import React, { Component } from "react";
import { Link } from "react-router-dom";
import { logout } from "../../utils/common";
import { Auth } from "aws-amplify";
import HeaderSubPart from "./HeaderSubPart";

let libertatelogo = require("../../images/libertate.png");
let insurecomplogo = require("../../images/Login_ic.png");
let altMarketLogo = require("../../images/alt_market_logo.png");

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logoSize: "large",
      loggedInPeo: undefined,
      isMobile: window.innerWidth < 900, // Initial mobile check
    };
  }

  // Define the handleResize function
  handleResize = () => {
    this.setState({ isMobile: window.innerWidth < 900 });
  };

  componentDidMount = async () => {
    window.addEventListener("resize", this.handleResize); // Add event listener
    try {
      let sessionData = await Auth.currentAuthenticatedUser();
      if (sessionData) {
        let userType = sessionData.attributes["custom:user_type"];
        if (userType === "peo") {
          let email = sessionData.attributes.email;
          let user_peo = email.split("@")[0];
          this.setState({ loggedInPeo: user_peo });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  componentWillUnmount() {
    // Remove the event listener when the component is unmounted
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    let { logoSize, loggedInPeo, isMobile } = this.state;
    console.log(isMobile, "isMobile");
    let isLoggedIn = sessionStorage.getItem("isLoggedIn");
    return (
      <>
        <nav
          id="navbarMain"
          className={`new-navbar container-fluid ${isLoggedIn ? "nav_style" : "nav_style_2"
            }`}
        >
          <div className="d-flex justify-content-between align-items-center w-100 no-gutters ">
            {process.env.REACT_APP_MODE == "beta" ? (
              <Link
                to={"/"}
                className="navbar-brand pride-logo d-flex align-items-center"
              >
                <span className="peo-header">
                  Professional
                  <br /> Employer
                  <br /> Organization
                </span>
              </Link>
            ) : (
              <Link
                className="navbar-brand mx-0 d-flex align-items-center navlisting"
                style={{ cursor: "default" }}
              >
                <img
                  className="imagesizelogo"
                  id="imagesizelogo"
                  style={
                    logoSize === "large"
                      ? { height: "100px", marginLeft: "2rem" }
                      : { height: "100px", marginLeft: "1.6rem" }
                  }
                  src={loggedInPeo === "ies" ? libertatelogo : altMarketLogo}
                  alt="libertate logo"
                />
              </Link>
            )}

            {isMobile ? " " : <HeaderSubPart />}

            <Link
              className="navbar-brand mx-0 d-flex align-items-center"
              style={{ flexDirection: "column", cursor: "default" }}
            >
              <img
                className="imagesizelogoicomp"
                id="imagesizelogoicomp"
                style={
                  logoSize === "large"
                    ? {
                      height: "70px",
                      marginRight: "2rem",
                    }
                    : {
                      height: "71px",
                      marginRight: "1rem",
                    }
                }
                src={insurecomplogo}
                alt="insurecomp logo"
              />
            </Link>
          </div>
        </nav>
      </>
    );
  }
}

export default Header;
