import React, {useContext} from "react";
import styles from "./PriceIndication.module.scss";
import {createPriceIndicationTable, submitQues} from "./utils/utils";
import {LibertateContext} from "../../../../context/Context";

function PriceIndication({
  data,
  selectedCarrier,
  overridesMap,
  updateOverridesMap,
}) {
  
  console.log('selectedCarrier:', selectedCarrier);

  const {overridesMap: updatedOverridesMap, pricingToolData: updatedPricingData, retainChecked, prePricingIndicationData, prevoverridesMap} = useContext(LibertateContext);

  const {classCodeTableData} = data || {};

  console.log("Selected Carrier", selectedCarrier)

  const recalculateQuote = async () => {
    try {
      debugger;
      let _overridesMap = JSON.parse(JSON.stringify(overridesMap));
      console.log('_overridesMap:', _overridesMap);
      let _preoverridesMap = JSON.parse(JSON.stringify(updatedOverridesMap));
      console.log('_preoverridesMap:', _preoverridesMap);
  
      let _prePricingToolData = JSON.parse(JSON.stringify(updatedPricingData));
      console.log('_prePricingToolData:', _prePricingToolData);
  
     
      let { brokerList } =
        _overridesMap.carrierDependent[selectedCarrier].overallModifiers;

      if (brokerList.length > 1 && !brokerList[brokerList.length - 1]?.name) {
        brokerList.pop();
      }
      brokerList = brokerList.filter(broker => broker.name && broker.name.trim() !== "");
      _overridesMap.carrierDependent[
        selectedCarrier
      ].overallModifiers.brokerList = brokerList;

      await submitQues(_overridesMap, _preoverridesMap, _prePricingToolData);
    } catch (error) {
      console.log(error);
    }
  };

  let fallbackCarrier = "";
  for (let carrier in classCodeTableData) {
    fallbackCarrier = carrier;
    break;
  }

  return (
    <section className={styles.indicationSection}>
      <div className="pink-header p-0 bg-white align-items-center">
        <div className="font-family-montserrat-bold headings">
          Price Indication
        </div>
      </div>
      <div className={`sectionContent ${styles.sectionContent}`}>
        <div className={`${styles.tableContainer} tableContainer`}>
          {createPriceIndicationTable(
            classCodeTableData?.[selectedCarrier || fallbackCarrier],
            updatedOverridesMap,
            prevoverridesMap,
            updateOverridesMap,
            retainChecked ? prevoverridesMap?.selectedCarrier : selectedCarrier, // Ensure correct carrier is passed
            styles,
            retainChecked
          )}


        </div>
        <div className={styles.actionButtons}>
          <div className={`${styles.calculateSection}`}>
            <button
              className={`btn btn-warning btn-lg btn-warning-shadow btnSubmits`}
              disabled={!selectedCarrier}
              label={!selectedCarrier && "Select a carrier"}
              onClick={recalculateQuote}
            >
              Calculate
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default React.memo(PriceIndication);
