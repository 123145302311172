import React, { Component } from "react";
import axios from "axios";
import { LibertateContext } from "../../../context/Context";
import { deleteAllCookies, showErrAlert } from "../../../utils/common";
import { awsUrl, awsUrl2 } from "../../../config";
import { Auth } from "aws-amplify";
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import Summary from "./pricing-tool/Summary";
import Modifiers from "./pricing-tool/Modifiers";
import Computation from "./pricing-tool/Computation";
import PriceIndication from "./pricing-tool/PriceIndication";
import LossInformation from "./LossInformation";
import toast, { Toaster } from "react-hot-toast";
import styles from "./FormCmprQuote.module.scss";
import { updateApprovalStatus } from "./pricing-tool/utils/utils";
const not_include_in_ncci = ["oh", "wa", "wy", "nd", "fl", "ca"];

let isMobile = window.innerWidth < 1000;
  class FormCmprQuote extends Component {
    constructor(props) {
      super(props);
      this.state = {
        data: {},
        fileURL_Map: {},
        fileURL_Map1: {},
        fileURL2: undefined,
        isNoLossChckd: false,
        selectedCarrier: "",
        emodStatesData: {},
        show_acord: {},
        acordSelectedState: {},
        acordIncludPremium: {},
        latest_ncci_key_program: {},
        overridesMap: {},
        loggedInUser: {
          isLoggedIn: false,
          data: { name: "", email: "", groups: [] },
        },
        prevoverridesMap: {}
      };
      this.getAllDataWRTCarrier = this.getAllDataWRTCarrier.bind(this);
    }
    UNSAFE_componentWillMount() {
      if (!window.location.hash) {
        window.location = window.location + "#loaded";
        window.location.reload();
      }
    }

  async componentDidMount() {
    $("html, body").animate({ scrollTop: 0 }, 250);

    let currProspectDetails = JSON.parse(
      sessionStorage.getItem("currProspect")
    );

    try {
      let currAuthUser = await Auth.currentAuthenticatedUser();
      let email = currAuthUser?.attributes?.email;
      let groups =
        currAuthUser?.signInUserSession?.accessToken?.payload?.[
          "cognito:groups"
        ];

      this.setState({
        loggedInUser: {
          isLoggedIn: true,
          data: { name: "", email, groups },
        },
      });
    } catch (error) {}

    let quoteData = {};
    try {
      quoteData = JSON.parse(sessionStorage.getItem("quoteData"));
    } catch (error) {}

    if (currProspectDetails) {
      let { emodStatesData } = currProspectDetails;
      this.setState({ emodStatesData });
    }

    var formStage = sessionStorage.getItem("formStage");
    console.log("++Form stage: ", formStage);

    console.log("++", currProspectDetails);
    if (
      formStage === "five" ||
      formStage === "six" ||
      formStage === "three" ||
      formStage === "four"
    ) {
      if (currProspectDetails) {
        let peo_value;
        let user_id = sessionStorage.getItem("user_id");
        axios
          .post(
            awsUrl2 + "/api/getPricingData",
            JSON.stringify({
              userId: user_id,
              quoteData,
            })
          )
          .then(async (response) => {
            let dataFromAPI = response.data;
            console.log("++dfa", dataFromAPI);
            this.props.setPricingToolData(dataFromAPI);
          })
          .catch((err) => {
            console.log("error: ", err);
            showErrAlert(err);
            $("#loader").css("display", "none");
          });
      } else {
        sessionStorage.setItem("formStage", "one");
        deleteAllCookies();
        sessionStorage.clear();
        window.location.reload();
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps?.overridesMap) !==
      JSON.stringify(this.props.overridesMap)
    ) {
      this.setState({ overridesMap: this.props.overridesMap });
    }
  }

  acordCheckboxHandler = (event, carrier, acordselected) => {
    console.log("acordselected: ", acordselected);
    console.log("carrier: ", carrier);
    let { acordIncludPremium, acordSelectedState } = JSON.parse(
      JSON.stringify(this.state)
    );
    acordIncludPremium[carrier][acordselected] =
      !acordIncludPremium?.[carrier]?.[acordselected];

    this.setState({ acordIncludPremium, acordSelectedState });
  };

  getAllDataWRTCarrier(carrier, data) {
    let calculated_data = "";
    let requireData = {};
    let places = [];
    let teapSum = 0;
    for (let addres in data) {
      requireData["status"] = data[addres].quote_status;
      requireData["timestamp"] = data[addres].date;
      places.push(addres);
      teapSum += Number(data[addres].teap);
      calculated_data = data[addres].calculated_data;
    }

    requireData["places"] = places;
    requireData["teapSum"] = teapSum;
    requireData["calculated_data"] = calculated_data;
    return requireData;
  }

  handleSubmit = async () => {
    try {
      $("#loader").css("display", "block");
      let isLoggedIn = await Auth.currentAuthenticatedUser();
      let emailID = isLoggedIn.attributes.email;
      let user_id = sessionStorage.getItem("user_id");
      let currentDate = Math.floor(Date.now()).toString();

      let userDetails = {
        user_email_id: user_id,
        status: "view_proposal",
        uploadTimestamp: currentDate,
        modifiedBy: emailID,
      };

      await axios
        .post(awsUrl2 + "/api/userTableData/update", userDetails)
        .then((res2) => {})
        .catch((err) => {
          console.log("Error:", err);
        });

      sessionStorage.setItem("formStage", "four");
      window.location.reload();
    } catch (error) {
      console.log(error);
    } finally {
      $("#loader").css("display", "none");
    }
  };

  setOverridesMap = (data) => {
    this.setState({ overridesMap: data });
  };

  updateOverridesMap = ({
    carrier,
    category,
    state,
    classCode,
    key,
    value,
  }) => {
    try {
      let _overridesMap = JSON.parse(JSON.stringify(this.state.overridesMap));
      console.log(
        carrier,
        category,
        state,
        classCode,
        key,
        value,
        _overridesMap
      );
      if (state) {
        if (carrier) {
          if (classCode) {
            _overridesMap.carrierDependent[carrier][category][key][state][
              classCode
            ] = value || 0;
          } else {
            _overridesMap.carrierDependent[carrier][category][key][state] =
              value || 0;
          }
        } else {
          _overridesMap.carrierIndependent[category][key][state] = value || 0;
        }
      } else {
        if (carrier) {
          _overridesMap.carrierDependent[carrier][category][key] = value || 0;
        } else {
          _overridesMap.carrierIndependent[category][key] = value || 0;
        }
      }

      this.setOverridesMap(_overridesMap);
    } catch (error) {
      console.log(error);
    }
  };

  toastSuccess = (text) =>
    toast.success(text, {
      duration: 3000,
    });

  toastError = (text) =>
    toast.error(text, {
      duration: 3000,
    });

  render() {
    let {
      updatePopUpData,
      pricingToolData,
      updateSelectedCarrier,
      calculateClicked,
      updatePricingToolData,
    } = this.props;
    let calculated_data = "";
    let {
      fileURL_Map,
      fileList,
      isNoLossChckd,
      fileURL2,
      startDate,
      show_acord,
      acordSelectedState,
      quoteSubmitted,
      fileURL_Map1,
      acordIncludPremium,
      latest_ncci_key_program,
      overridesMap,
      prevoverridesMap,
      loggedInUser,
    } = this.state;

    let tabList = [];

    let salesforceData;
    try {
      salesforceData =
        JSON.parse(sessionStorage.getItem("salesforceData")) || null;
    } catch (error) {}

    let _quoteSubmitted = quoteSubmitted ? true : false;

    let { approvalStatus, requestApprovalEnabled } = pricingToolData;

    const buttonEnableCriteria = {
      sendToSunz: () => {
        return ["carrier_ae", "carrier_s"].includes(
          pricingToolData?.selectedCarrier
        );
      },
      sendToUw: () => {
        return ["carrier_k"].includes(pricingToolData?.selectedCarrier);
      },
      requestApproval: () => {
        return loggedInUser?.data?.groups?.includes(
          process.env.REACT_APP_SALES_GROUP
        );
      },
      decisionSection: () => {
        return loggedInUser?.data?.groups?.includes(
          process.env.REACT_APP_ADMIN_GROUP
        );
      },
    };

    let buttonDisplayCarrierName = "SUNZ";
    try {
      if (process.env.REACT_APP_MODE === "beta") {
        buttonDisplayCarrierName = "Carrier B";
      }
    } catch (error) {}

    if (isNoLossChckd && fileURL2 && startDate) {
      tabList.push({
        tabName: "No Loss",
        children: (
          <iframe
            id={`iframeNoLoss`}
            src={fileURL2 || "about:blank" + "#toolbar=0"}
            style={{ border: "none", height: "645px" }}
            width="100%"
          ></iframe>
        ),
      });
    }

    for (let carr in show_acord) {
      let fileURL,
        state_shown,
        non_ncci_used = false;

      for (let _state of not_include_in_ncci) {
        let __state = _state.toUpperCase();
        if (acordSelectedState?.[carr] === __state) {
          state_shown = __state;
          non_ncci_used = true;
          if (acordIncludPremium?.[carr]?.[__state]) {
            fileURL = fileURL_Map1?.[carr]?.[__state];
          } else {
            fileURL = fileURL_Map?.[carr]?.[__state];
          }
        }
      }

      if (non_ncci_used === true) {
      } else if (
        latest_ncci_key_program?.[carr] &&
        fileURL_Map?.[carr]?.["Ncci"]?.[latest_ncci_key_program[carr]]
      ) {
        state_shown = "Ncci";
        if (acordIncludPremium?.[carr]?.["Ncci"]) {
          fileURL = fileURL_Map1[carr]["Ncci"][latest_ncci_key_program[carr]];
        } else {
          fileURL = fileURL_Map[carr]["Ncci"][latest_ncci_key_program[carr]];
        }
      }

      // let { fileURL, state_shown } =
      //   acordSelectedState?.[carr] == "CA"
      //     ? { fileURL: fileURL_Map?.[carr]?.["CA"], state_shown: "CA" }
      //     : acordSelectedState?.[carr] == "FL"
      //       ? { fileURL: fileURL_Map?.[carr]?.["FL"], state_shown: "FL" }
      //       : (
      //         latest_ncci_key_program?.[carr] && fileURL_Map?.[carr]?.["Ncci"]?.[latest_ncci_key_program[carr]] ?
      //           { fileURL: fileURL_Map[carr]["Ncci"][latest_ncci_key_program[carr]], state_shown: "Ncci" } : { fileURL: "", state_shown: "" }
      //       );

      if (fileURL)
        tabList.push({
          tabName: carr,
          state_shown: state_shown,
          children: (
            <div
              className={
                isMobile ? "accord-container-mobile" : "accord-container"
              }
            >
              <div
                className={
                  isMobile ? "accord-toolbar-mobile" : "accord-toolbar"
                }
              >
                {isMobile ? (
                  ""
                ) : (
                  <a href={fileURL} download={carr} title={`Download: ${carr}`}>
                    <img
                      height="30"
                      src={require("../../../images/download-file.png")}
                    ></img>
                  </a>
                )}
                <div className="ml-2">
                  <label>
                    <input
                      type="checkbox"
                      checked={
                        this.state.acordIncludPremium?.[carr]?.[
                          acordSelectedState?.[carr]
                        ]
                      }
                      onChange={(event) =>
                        this.acordCheckboxHandler(
                          event,
                          carr,
                          acordSelectedState?.[carr]
                        )
                      }
                    />
                  </label>
                  <b style={{ fontSize: "13px" }}>
                    {" "}
                    Include Premium and Carrier Name
                  </b>
                </div>
                {isMobile ? (
                  <a
                    href={fileURL}
                    download={carr}
                    title={`Download: ${carr}`}
                    style={{ paddingLeft: "2rem", paddingTop: "15px" }}
                  >
                    <img
                      height="30"
                      src={require("../../../images/download-file.png")}
                    ></img>
                    <span style={{ color: "white" }}>
                      {isMobile ? "Download Acord" : ""}
                    </span>
                  </a>
                ) : (
                  ""
                )}
              </div>
              {isMobile ? (
                ""
              ) : (
                <iframe
                  id={`iframeAccord`}
                  src={fileURL || "about:blank" + "#toolbar=0"}
                  style={{ border: "none", height: "645px" }}
                  width="100%"
                ></iframe>
              )}
            </div>
          ),
        });
    }

    if (this.state.data !== undefined && this.state.data !== null) {
      for (let carrier in this.state.data) {
        let requireData = this.getAllDataWRTCarrier(
          carrier,
          this.state.data[carrier]
        );
        calculated_data = requireData.calculated_data;
      }
    }

    if (fileList === undefined || fileList === null) {
      fileList = {
        first: {},
        second: {},
      };
    }

    const link_ref_instance = {
      dev: (id) =>
        `https://altmkt--icsandbox.sandbox.lightning.force.com/lightning/r/Opportunity/${id}/view`,
      beta: (id) =>
        `https://altmkt--icsandbox.sandbox.lightning.force.com/lightning/r/Opportunity/${id}/view`,
      prod: (id) =>
        `https://altmkt.lightning.force.com/lightning/r/Opportunity/${id}/view`,
    };

    return (
      <div id="cmprQts" className="container-fluid per98">
        <Summary data={pricingToolData?.summaryData} />
        <Modifiers
          data={pricingToolData?.modifiersData}
          carrierList={pricingToolData?.carrierList}
          updateSelectedCarrier={updateSelectedCarrier}
          selectedCarrier={pricingToolData?.selectedCarrier}
          overridesMap={overridesMap}
          updateOverridesMap={this.updateOverridesMap}
          setOverridesMap={this.setOverridesMap}
          preoverridesMap={prevoverridesMap}
        />
        <PriceIndication
          data={pricingToolData?.priceIndicationData}
          selectedCarrier={pricingToolData?.selectedCarrier}
          overridesMap={overridesMap}
          preoverridesMap={prevoverridesMap}
          updateOverridesMap={this.updateOverridesMap}
        />
        <Computation
          data={pricingToolData?.computationData}
          selectedCarrier={pricingToolData?.selectedCarrier}
          approvalStatus={pricingToolData?.approvalStatus}
          calculateClicked={calculateClicked}
          requestApprovalEnabled={pricingToolData?.requestApprovalEnabled}
          updatePricingToolData={updatePricingToolData}
        />
        <LossInformation
          fileListToStore={pricingToolData?.fileList}
          styles={styles}
          updatePopUpData={updatePopUpData}
        />
        <div className={styles.decisionSection}>
          {buttonEnableCriteria?.requestApproval() && (
            <button
              className={`btn btn-warning btn-lg btn-warning-shadow btnSubmits`}
              onClick={async () =>
                updatePopUpData({
                  show: true,
                  title: "",
                  children: (
                    <h4>
                      On click of Yes the submission will be sent to Admin for
                      pricing approval.
                    </h4>
                  ),
                  acceptBtn: "Yes",
                  rejectBtn: "No",
                  acceptBtnCallback: async () => {
                    {
                      if (calculateClicked && requestApprovalEnabled) {
                        await updateApprovalStatus(
                          "requested",
                          {
                            toastSuccess: this.toastSuccess,
                            toastError: this.toastError,
                          },
                          undefined,
                          updatePopUpData
                        );
                        updatePricingToolData("requestApprovalEnabled", false);
                      }
                    }
                  },
                  rejectBtnCallback: () => {},
                })
              }
              disabled={!calculateClicked || !requestApprovalEnabled}
            >
              Send for Approval
            </button>
          )}
          {buttonEnableCriteria?.decisionSection() && (
            <>
              <button
                className={`btn btn-warning btn-lg btn-warning-shadow approveBtnSubmits`}
                disabled={
                  approvalStatus === "submitted" ||
                  approvalStatus === "approved" ||
                  approvalStatus === "rejected" ||
                  !calculateClicked
                }
                onClick={() => {
                  if (
                    !(
                      approvalStatus === "submitted" ||
                      approvalStatus === "approved" ||
                      approvalStatus === "rejected" ||
                      !calculateClicked
                    )
                  ) {
                    updateApprovalStatus(
                      "approved",
                      {
                        toastSuccess: this.toastSuccess,
                        toastError: this.toastError,
                      },
                      undefined,
                      updatePopUpData
                    );
                    updatePricingToolData("approvalStatus", "approved");
                  }
                }}
              >
                Approve
              </button>
              <button
                className={`btn btn-warning btn-lg btn-warning-shadow rejectBtnSubmits`}
                disabled={
                  approvalStatus === "submitted" ||
                  approvalStatus === "approved" ||
                  approvalStatus === "rejected" ||
                  !calculateClicked
                }
                onClick={() => {
                  if (
                    !(
                      approvalStatus === "submitted" ||
                      approvalStatus === "approved" ||
                      approvalStatus === "rejected" ||
                      !calculateClicked
                    )
                  ) {
                    updateApprovalStatus(
                      "rejected",
                      {
                        toastSuccess: this.toastSuccess,
                        toastError: this.toastError,
                      },
                      undefined,
                      updatePopUpData
                    );
                    updatePricingToolData("approvalStatus", "rejected");
                  }
                }}
              >
                Reject
              </button>
            </>
          )}
          {pricingToolData?.selectedCarrier && (
            <>
              {buttonEnableCriteria?.sendToSunz() && (
                <button
                  className={`btn btn-warning btn-lg btn-warning-shadow btnSubmits`}
                  disabled={approvalStatus !== "approved"}
                  onClick={() => {
                    salesforceData
                      ? window.open(
                          link_ref_instance[process.env.REACT_APP_MODE](
                            salesforceData.opportunityId
                          ),
                          "_blank",
                          "noopener,noreferrer"
                        )
                      : updatePopUpData({
                          show: true,
                          title: "",
                          children: (
                            <h4>
                              On click of Yes the Submission will be sent to
                              SUNZ team for underwriting
                            </h4>
                          ),
                          acceptBtn: "Yes",
                          rejectBtn: "No",
                          acceptBtnCallback: () => {
                            {
                              if (approvalStatus === "approved") {
                                updateApprovalStatus(
                                  "submitted",
                                  {
                                    toastSuccess: this.toastSuccess,
                                    toastError: this.toastError,
                                  },
                                  "sunz",
                                  updatePopUpData
                                );
                                updatePricingToolData(
                                  "approvalStatus",
                                  "submitted"
                                );
                              }
                            }
                          },
                          rejectBtnCallback: () => {},
                        });
                  }}
                >
                  Send to {buttonDisplayCarrierName}
                </button>
              )}
              {buttonEnableCriteria?.sendToUw() && (
                <button
                  className={`btn btn-warning btn-lg btn-warning-shadow btnSubmits`}
                  disabled={approvalStatus !== "approved"}
                  onClick={() => {
                    salesforceData
                      ? window.open(
                          link_ref_instance[process.env.REACT_APP_MODE](
                            salesforceData.opportunityId
                          ),
                          "_blank",
                          "noopener,noreferrer"
                        )
                      : updatePopUpData({
                          show: true,
                          title: "",
                          children: (
                            <h4>
                              On click of Yes the Submission will be sent to
                              Libertate team for SAIC underwriting.
                            </h4>
                          ),
                          acceptBtn: "Yes",
                          rejectBtn: "No",
                          acceptBtnCallback: () => {
                            if (approvalStatus === "approved") {
                              updateApprovalStatus(
                                "submitted",
                                {
                                  toastSuccess: this.toastSuccess,
                                  toastError: this.toastError,
                                },
                                "saic",
                                updatePopUpData
                              );
                              updatePricingToolData(
                                "approvalStatus",
                                "submitted"
                              );
                            }
                          },
                          rejectBtnCallback: () => {},
                        });
                  }}
                >
                  Send to UW
                </button>
              )}
            </>
          )}
          <button
            type="button"
            className="btn btn-warning btn-lg btn-warning-shadow btnSubmits"
            onClick={() => {
              if (calculateClicked) this.handleSubmit();
            }}
            disabled={!calculateClicked}
          >
            View Proposal
          </button>
        </div>
        <Toaster />
      </div>
    );
  }
}
export default function FormCmprQuoteWrapper(props) {
  return (
    <LibertateContext.Consumer>
      {(context) => (
        <FormCmprQuote
          calculateClicked={context.calculateClicked}
          updatePricingToolData={context.updatePricingToolData}
          overridesMap={context.overridesMap}
          prevoverridesMap={context.prevoverridesMap}
          prePricingData={context.prePricingIndicationData}
          pricingToolData={context.pricingToolData}
          setPricingToolData={context.setPricingToolData}
          updateSelectedCarrier={context.updateSelectedCarrier}
          updatePopUpData={props.updatePopUpData}
          setPerStateQuoteTableData={context.setPerStateQuoteTableData}
          setStateCarrierData={context.setStateCarrierData}
          setPrograms={context.setPrograms}
          setMasterWithoutFundRateMap={context.setMasterWithoutFundRateMap}
          setProgramStateType={context.setProgramStateType}
          setPeoData={context.setPeoData}
          setExcludedClassCodeMap={context.setExcludedClassCodeMap}
          setCurrentCarrierMap={context.setCurrentCarrierMap}
          setEnableUnderwritingPage={props.setEnableUnderwritingPage}
          setUwNetRates={context.setUwNetRates}
          setUwFileList={context.setUwFileList}
          setCarrierBasedData={context.setCarrierBasedData}
        />
      )}
    </LibertateContext.Consumer>
  );
}
