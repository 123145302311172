let mode;

if (process.env.REACT_APP_MODE) {
  mode = process.env.REACT_APP_MODE;
} else {
  mode = "dev";
}
console.log("env", process.env);

var localURL = "http://localhost:3000/dev";

// mode = "local2";

export var demoAuthUrl =
  "https://toyg4ps5yl.execute-api.us-east-1.amazonaws.com/dev";

// server_coreSetURL1;
export var awsUrl =
  "https://0b1ucnqh3h.execute-api.us-east-1.amazonaws.com/dev";
var awsUrlBeta = "https://cn0qigooqk.execute-api.us-east-1.amazonaws.com/beta";
var awsUrlProd = "https://7z32vw3a8i.execute-api.us-east-1.amazonaws.com/prod";
export var awsAcordUpload =
  "https://5swuplkv50.execute-api.us-east-1.amazonaws.com/dev";
var awsAcordUploadProd =
  "https://ofmfwmokg6.execute-api.us-east-1.amazonaws.com/prod";

// server_coreSetURL2;
export var awsUrl2 =
  "https://t93d5aifii.execute-api.us-east-1.amazonaws.com/dev";
var awsUrlBeta2 = "https://a9mltpard2.execute-api.us-east-1.amazonaws.com/beta";
var awsUrlProd2 = "https://m941m1mpzi.execute-api.us-east-1.amazonaws.com/prod";

// dashboard URL
export var dashboardUrl =
  "https://dmuwt3vd0e.execute-api.us-east-1.amazonaws.com/dev";
var dashboardUrlProd = "";

// pibit URL dev & prod

export var pibitUrl =
  "https://qypm1jivib.execute-api.us-east-1.amazonaws.com/dev";
var pibitUrlBeta =
  "https://wy69228799.execute-api.us-east-1.amazonaws.com/beta";
var pibitUrlProd =
  "https://thcgya5ipc.execute-api.us-east-1.amazonaws.com/prod";

// for dev and beta environment client-id for all instances will be same, but it will differ in prod.
export var clientId = 103;
var clientIdProd = 110;

// This key is added in db to differentiate client, since for dev environment pibit will have single client code.
// Client code for prod will differ.
export var clientIdInternal = "103-2";
var clientIdInternalProd = 80;

if (mode === "local1") {
  awsUrl = localURL;
  // awsAcordUpload = localURL;
} else if (mode === "local2") {
  awsUrl2 = localURL;
  // awsAcordUpload = localURL;
} else if (mode === "locald") {
  dashboardUrl = localURL;
} else if (mode === "prod") {
  awsUrl = awsUrlProd;
  awsUrl2 = awsUrlProd2;
  dashboardUrl = dashboardUrlProd;
  pibitUrl = pibitUrlProd;
  clientId = clientIdProd;
  clientIdInternal = clientIdInternalProd;
  awsAcordUpload = awsAcordUploadProd;
} else if (mode === "beta") {
  awsUrl = awsUrlBeta;
  awsUrl2 = awsUrlBeta2;
  dashboardUrl = dashboardUrlProd;
  (pibitUrl = pibitUrlBeta), (clientIdInternal = clientIdInternal);
} else if (mode === "dev") {
  pibitUrl = pibitUrl;
  clientId = clientId;
  clientIdInternal = clientIdInternal;
}

export const versionNumber = "1.0.4 (m)";
