import React, { Component, forwardRef } from "react";
import axios from "axios";
import numeral from "numeral";
import { awsUrl2, awsUrl } from "../../config";
import Clamp from "react-multiline-clamp";
import moment from "moment";
import MaterialTable from "material-table";
import { TablePagination } from "@mui/material";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Search from "@mui/icons-material/Search";
import Clear from "@mui/icons-material/Clear";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Check from "@mui/icons-material/Check";
import Loader from "../common/Loader";
import { Nav } from "react-bootstrap";
import Header from "../common/Header";
import { Auth } from "aws-amplify";
import SideTab from "../../newcomponent/common/SideTab";
import ToggleSwitch from "../../utils/ToggleSwitch";
import Popup from "../common/Popup";
import toast, { Toaster } from "react-hot-toast";
import {
  saveInUserstatusTable,
  saveInUserTable,
} from "../../utils/form_compPro";
import { ThemeProvider, createTheme } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { setCommentBoxColor } from "../../utils/common";
import { RotatingLines } from "react-loader-spinner";
import downloadimage from "../../images/file-download-circle-green.svg";
import { DownloadDashboardFiles } from "../subcompo/DownloadDashboardFiles";
import { Modal } from "react-bootstrap";
import parse from "html-react-parser";
import DealWonLostButtons from "../subcompo/DealWonLostButtons";
import ConfirmDealButton from "../subcompo/ConfirmDealButton";
import LoadingOverlay from "react-loading-overlay";
import { fetchAdditionalDetails } from "../../utils/dashboard_util";
import SaveAlt from "@mui/icons-material/SaveAlt";

let ismobile_font = window.innerWidth < 1600;
const tableIcons = {
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  // Filter: forwardRef((props,ref) => <Filter {...props} ref={ref} />)
  Filter: () => <></>,
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
};

let isMobile_sidenav = window.innerWidth < 900;
class DashboardLibertate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "tab1",
      modalBody: [],
      openModal: false,
      agentButton: false,
      loggedInUserDetails: {
        isLoggedIn: false,
        data: { name: "", email: "", groups: [] },
      },
      tabsMap: {},
      dealMap: {},
      confirmDealMap: {},
      userDataList: [],
      pricingRequestList: [],
      wonList: [],
      lostList: [],
      wonLostLoading: true,
      wonLostDataLoading: true,
      userDataListLoading: true,
      userDataListDataLoading: true,
      pricingRequestListLoading: true,
      pricingRequestListDataLoading: true,
      submissionYesNoListUserData: {},
      submissionYesNoListPricingRequest: {},
      agentCommentsHistoryUserData: {},
      agentCommentsHistoryPricingRequest: {},
    };
  }
  componentDidMount() {
    // Immediately invoked async function
    (async () => {
      try {
        const loggedInUserDetails = await this.fetchUserDetails();

        // Use userDetails directly here
        const tabsMap = {
          tab1: "User Dashboard",
        };

        // display the pricing request tab if an admin is logged in
        if (
          loggedInUserDetails?.data?.groups?.includes(
            process.env.REACT_APP_ADMIN_GROUP
          )
        ) {
          tabsMap["tab2"] = "Pricing Request";
        }
        tabsMap["tab3"] = "Won";
        tabsMap["tab4"] = "Lost";
        // Update state with userDetails
        this.setState({
          loggedInUserDetails: loggedInUserDetails,
          tabsMap: tabsMap,
        });

        const updateDisplayForTab = async (tabName) => {
          if (tabName === "tab1" || tabName === "tab2") {
            const loggedInGroups = loggedInUserDetails?.data?.groups;
            await this.updateDisplay(tabName, loggedInGroups);
          }
        };

        // Create an array of promises
        const updatePromises = Object.keys(tabsMap).map((tabName) =>
          updateDisplayForTab(tabName)
        );

        // Wait for all promises to complete
        await Promise.all(updatePromises);
      } catch (error) {
        console.error("Error in componentDidMount:", error);
      }
    })();
  }

  updateDisplay = async (tabName, loggedInGroups) => {
    try {
      let sessionData = await Auth.currentAuthenticatedUser();
      if (sessionData) {
        let userType = sessionData.attributes["custom:user_type"];
        let req_body = {
          first_time_loading: "true",
        };
        let _userDataList = [];
        let _pricingRequestList = [];
        let _wonList = [];
        let _lostList = [];
        let _dealMap = {};
        let _submissionYesNoList = {};
        let _agentCommentsHistory = {};
        let last_key;

        let pricingStatusMap = {
          undefined: "-",
          requested: "Pending",
          approved: "Approved",
          rejected: "Rejected",
          submitted: "Submitted",
        };

        if (loggedInGroups?.includes(process.env.REACT_APP_ADMIN_GROUP)) {
          req_body["tabName"] = `admin_${tabName}`;
        }

        await new Promise((resolve, reject) => {
          axios
            .post(awsUrl2 + "/api/userTableData/getAll", req_body)
            .then((res) => {
              let userDataList = [];
              let rowData;
              let step;

              let emailToNameMap = {
                "akshay@mobiux.in": "Akshay",
              };

              userDataList = res.data.list;
              last_key = res.data.old_key;

              // console.log(userDataList, "++++++++++++++++++++++++++++");

              for (let userDataRow in userDataList) {
                let userData = userDataList[userDataRow];
                let sortKeyPrepare = [];
                try {
                  if (
                    userData.status === "quote_generated" ||
                    userData.status === "view_proposal"
                  ) {
                    step = "Quote Generated";
                  } else if (userData.status === "company_profile") {
                    step = "Underwriting Page";
                  } else {
                    step = "-";
                  }

                  // Calculating total Payroll

                  let payroll = 0;
                  let classcodeInfo;
                  let sum = 0;

                  let childloc = userData.childrenLoc;
                  // console.log(userData)

                  for (let userDataRow in childloc) {
                    let userChildlocData = childloc[userDataRow];
                    // console.log(userChildlocData);
                    classcodeInfo = userChildlocData.classCodesInfo;
                    classcodeInfo = Object.entries(classcodeInfo);
                    // console.log(classcodeInfo)
                    for (let i = 0; i < classcodeInfo.length; i++) {
                      let paydata = classcodeInfo[i];
                      const stringAmount = paydata[1]?.payroll?.value;
                      if (!stringAmount) continue;
                      const numberAmount = parseFloat(
                        stringAmount.replace(/[^\d.-]/g, "")
                      );
                      sum = sum + numberAmount;
                    }
                    // console.log(payroll)
                  }

                  payroll = sum;

                  let modifiedBy;

                  if (
                    userData.modifiedBy &&
                    userData.modifiedBy in emailToNameMap
                  ) {
                    modifiedBy =
                      emailToNameMap[userData.modifiedBy.trim().toLowerCase()];
                  } else {
                    modifiedBy = userData.modifiedBy;
                  }

                  for (let key of userData?.address || []) {
                    sortKeyPrepare.push(
                      `${userData?.quoteData?.date}@${userData?.quoteData?.uuid}_${userData?.peoDetails?.selectedPeo}+${key}`
                    );
                  }

                  //  Process to get Governing class code.

                  let governingClassCode;
                  let govClassCode = [];
                  let maxPayroll = -Infinity;
                  let govccstate;
                  const childrenLoc = userData.childrenLoc;

                  // Loop through each location
                  if (childrenLoc) {
                    Object.values(childrenLoc).map((location) => {
                      // Check if classCodesInfo property exists and has values
                      if (location.classCodesInfo) {
                        // Loop through classCodesInfo to get payroll details
                        Object.values(location.classCodesInfo).map(
                          (classCode) => {
                            if (classCode) {
                              let classCodeobj = {
                                payroll: this.formatPayrollToNumber(
                                  classCode.payroll.value
                                ),
                                classCode:
                                  classCode.classCodeDescription?.value?.split(
                                    ":"
                                  )[0],
                                govccstate: location.state,
                              };
                              govClassCode.push(classCodeobj);
                            }
                          }
                        );
                      }
                    });
                  }
                  govClassCode.forEach((item) => {
                    if (item.payroll > maxPayroll) {
                      maxPayroll = item.payroll;
                      governingClassCode = item.classCode;
                      govccstate = item.govccstate.value;
                    }
                  });

                  rowData = {
                    lossRunUploaded: userData?.uploadedLossRun,
                    lossSummaryData: userData?.summaryData,
                    step,
                    uploadTimestamp: userData.uploadTimestamp
                      ? moment(userData.uploadTimestamp, "x").format("M/D/YY")
                      : "-",
                    effectiveDate: userData?.companyProfile?.effectiveDate
                      ?.value
                      ? moment(
                          userData?.companyProfile?.effectiveDate?.value
                        ).format("M/D/YY")
                      : "-",
                    phoneNumber: userData?.companyProfile?.phoneNumber?.value,
                    contactName:
                      this.camelCase(
                        userData?.companyProfile?.firstName?.value
                      ) +
                      " " +
                      this.camelCase(userData?.companyProfile?.lastName?.value),
                    companyName:
                      userData?.companyProfile?.companyName?.value || "-",
                    fein: userData?.companyProfile?.fein?.value,
                    modifiedBy: modifiedBy || "-",
                    createdDate: userData?.createdDate
                      ? moment(userData?.createdDate, "x").format("M/D/YY")
                      : "-",
                    childrenLoc: userData?.childrenLoc,
                    payroll: payroll?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                    }),
                    companyProfile: userData?.companyProfile,
                    state:
                      userData?.companyProfile?.state1?.value?.toUpperCase() ||
                      "-",
                    emodStatesData: userData?.emodStatesData,
                    uw_ques: userData?.uw_ques,
                    user_email_id: userData?.user_email_id,
                    status: userData?.status,
                    quoteData: userData?.quoteData,
                    address: userData?.address,
                    status: userData?.status,
                    riskProfile: userData?.riskProfile,
                    peoDetails: userData?.peoDetails,
                    carrierList: userData?.carrierList,
                    amtrustAccountDetails: userData?.amtrustAccountDetails,
                    quoteSubmitted: userData?.quoteSubmitted,
                    uw_comments: userData?.uw_comments
                      ? typeof userData?.uw_comments === "string"
                        ? [userData?.uw_comments]
                        : userData?.uw_comments
                      : [""],
                    agent_comments: userData?.agent_comments
                      ? typeof userData.agent_comments === "string"
                        ? [userData?.agent_comments]
                        : userData?.agent_comments
                      : [""],
                    uwStatus: userData?.uw_status,
                    uuidCarriers: sortKeyPrepare,
                    govClassCode: governingClassCode,
                    govCCState: govccstate,
                    selectedPeo: userData?.peoDetails?.selectedPeo,
                    salesforceData: userData?.salesforceData,
                    selectedPeoToDisplay: this.selectedPeoFormat(
                      userData?.peoDetails?.selectedPeo
                    ),
                    pricingStatus:
                      pricingStatusMap?.[userData?.approval_status],
                    miscSalesforceData: userData?.miscSalesforceData,
                    fileList: userData?.fileList ? userData?.fileList : [],
                    deal_won: userData?.deal_won,
                    deal_lost: userData?.deal_lost,
                    deal_confirmed: userData?.deal_confirmed,
                  };

                  _submissionYesNoList[rowData.user_email_id] =
                    userData.real_submission === "true" ? true : false;

                  _agentCommentsHistory[rowData.user_email_id] =
                    userData?.agent_comments
                      ? typeof userData.agent_comments === "string"
                        ? [userData?.agent_comments]
                        : userData?.agent_comments
                      : [""];
                  if (rowData.companyName === "-") continue;

                  if (tabName === "tab1") {
                    if (rowData?.deal_confirmed) {
                      if (rowData?.deal_won) {
                        _wonList.push(rowData);
                      } else if (rowData?.deal_lost) {
                        _lostList.push(rowData);
                      }
                    } else {
                      _userDataList.push(rowData);
                      if (rowData?.deal_won) {
                        _dealMap[rowData.user_email_id] = true;
                      } else if (rowData?.deal_lost) {
                        _dealMap[rowData.user_email_id] = false;
                      }
                    }
                  } else if (tabName === "tab2") {
                    _pricingRequestList.push(rowData);
                  }
                } catch (error) {
                  console.log("error in: ", userData, error);
                }
              }

              if (tabName === "tab1") {
                this.setState({
                  wonLostLoading: false,
                  userDataListLoading: false,
                  userDataList: _userDataList,
                  wonList: _wonList,
                  lostList: _lostList,
                  dealMap: _dealMap,
                });
              } else {
                this.setState({
                  pricingRequestListLoading: false,
                  pricingRequestList: _pricingRequestList,
                });
              }

              if (tabName === "tab1") {
                this.setState((prevstate) => {
                  let submissionYesNoList = JSON.parse(
                    JSON.stringify(prevstate.submissionYesNoListUserData)
                  );
                  let agentCommentsHistory = JSON.parse(
                    JSON.stringify(prevstate.agentCommentsHistoryUserData)
                  );
                  return {
                    submissionYesNoListUserData: {
                      ..._submissionYesNoList,
                      ...submissionYesNoList,
                    },
                    agentCommentsHistoryUserData: {
                      ..._agentCommentsHistory,
                      ...agentCommentsHistory,
                    },
                  };
                });
              } else {
                this.setState((prevstate) => {
                  let submissionYesNoList = JSON.parse(
                    JSON.stringify(prevstate.submissionYesNoListPricingRequest)
                  );
                  let agentCommentsHistory = JSON.parse(
                    JSON.stringify(prevstate.agentCommentsHistoryPricingRequest)
                  );
                  return {
                    submissionYesNoListPricingRequest: {
                      ..._submissionYesNoList,
                      ...submissionYesNoList,
                    },
                    agentCommentsHistoryPricingRequest: {
                      ..._agentCommentsHistory,
                      ...agentCommentsHistory,
                    },
                  };
                });
              }
              resolve();
            })
            .catch((error) => {
              console.log("error", error);
              reject();
            });
        });

        do {
          if (last_key) {
            req_body["lastEvaluatedKey"] = last_key;
          }

          delete req_body["first_time_loading"];

          await new Promise((resolve, reject) => {
            axios
              .post(awsUrl2 + "/api/userTableData/getAll", req_body)
              .then((res) => {
                let userDataList = [];
                let rowData;
                let step;

                let emailToNameMap = {
                  "akshay@mobiux.in": "Akshay",
                };

                userDataList = res.data.list;
                last_key = res.data.old_key;

                // console.log(userDataList, "++++++++++++++++++++++++++++");

                for (let userDataRow in userDataList) {
                  let userData = userDataList[userDataRow];
                  let sortKeyPrepare = [];
                  try {
                    if (
                      userData.status === "quote_generated" ||
                      userData.status === "view_proposal"
                    ) {
                      step = "Quote Generated";
                    } else if (userData.status === "company_profile") {
                      step = "Underwriting Page";
                    } else {
                      step = "-";
                    }

                    // Calculating total Payroll

                    let payroll = 0;
                    let classcodeInfo;
                    let sum = 0;

                    let childloc = userData.childrenLoc;
                    // console.log(userData)

                    for (let userDataRow in childloc) {
                      let userChildlocData = childloc[userDataRow];
                      // console.log(userChildlocData);
                      classcodeInfo = userChildlocData.classCodesInfo;
                      classcodeInfo = Object.entries(classcodeInfo);
                      // console.log(classcodeInfo)
                      for (let i = 0; i < classcodeInfo.length; i++) {
                        let paydata = classcodeInfo[i];
                        const stringAmount = paydata[1]?.payroll?.value;
                        if (!stringAmount) continue;
                        const numberAmount = parseFloat(
                          stringAmount.replace(/[^\d.-]/g, "")
                        );
                        sum = sum + numberAmount;
                      }
                      // console.log(payroll)
                    }
                    payroll = sum;

                    let modifiedBy;

                    if (
                      userData.modifiedBy &&
                      userData.modifiedBy in emailToNameMap
                    ) {
                      modifiedBy =
                        emailToNameMap[
                          userData.modifiedBy.trim().toLowerCase()
                        ];
                    } else {
                      modifiedBy = userData.modifiedBy;
                    }

                    for (let key of userData?.address || []) {
                      sortKeyPrepare.push(
                        `${userData?.quoteData?.date}@${userData?.quoteData?.uuid}_${userData?.peoDetails?.selectedPeo}+${key}`
                      );
                    }

                    // Calculating Highest payroll and finding Governing class code

                    let governingClassCode;
                    let govClassCode = [];
                    let maxPayroll = -Infinity;
                    let govccstate;
                    const childrenLoc = userData.childrenLoc;

                    if (childrenLoc) {
                      Object.values(childrenLoc).map((location) => {
                        // Check if classCodesInfo property exists and has values
                        if (location.classCodesInfo) {
                          // Loop through classCodesInfo to get payroll details
                          Object.values(location.classCodesInfo).map(
                            (classCode) => {
                              if (classCode) {
                                let classCodeobj = {
                                  payroll: this.formatPayrollToNumber(
                                    classCode.payroll.value
                                  ),
                                  classCode:
                                    classCode.classCodeDescription?.value?.split(
                                      ":"
                                    )[0],
                                  govccstate: location.state,
                                };
                                govClassCode.push(classCodeobj);
                              }
                            }
                          );
                        }
                      });
                    }
                    govClassCode.forEach((item) => {
                      if (item.payroll > maxPayroll) {
                        maxPayroll = item.payroll;
                        governingClassCode = item.classCode;
                        govccstate = item.govccstate.value;
                      }
                    });

                    rowData = {
                      lossRunUploaded: userData?.uploadedLossRun,
                      lossSummaryData: userData?.summaryData,
                      step,
                      uploadTimestamp: userData.uploadTimestamp
                        ? moment(userData.uploadTimestamp, "x").format("M/D/YY")
                        : "-",
                      effectiveDate: userData?.companyProfile?.effectiveDate
                        ?.value
                        ? moment(
                            userData?.companyProfile?.effectiveDate?.value
                          ).format("M/D/YY")
                        : "-",
                      phoneNumber: userData?.companyProfile?.phoneNumber?.value,
                      contactName:
                        this.camelCase(
                          userData?.companyProfile?.firstName?.value
                        ) +
                        " " +
                        this.camelCase(
                          userData?.companyProfile?.lastName?.value
                        ),
                      companyName:
                        userData?.companyProfile?.companyName?.value || "-",
                      fein: userData?.companyProfile?.fein?.value,
                      modifiedBy: modifiedBy || "-",
                      createdDate: userData?.createdDate
                        ? moment(userData?.createdDate, "x").format("M/D/YY")
                        : "-",
                      childrenLoc: userData?.childrenLoc,
                      payroll: payroll?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                      }),
                      companyProfile: userData?.companyProfile,
                      state:
                        userData?.companyProfile?.state1?.value?.toUpperCase() ||
                        "-",
                      emodStatesData: userData?.emodStatesData,
                      uw_ques: userData?.uw_ques,
                      user_email_id: userData?.user_email_id,
                      status: userData?.status,
                      quoteData: userData?.quoteData,
                      address: userData?.address,
                      status: userData?.status,
                      riskProfile: userData?.riskProfile,
                      peoDetails: userData?.peoDetails,
                      carrierList: userData?.carrierList,
                      amtrustAccountDetails: userData?.amtrustAccountDetails,
                      quoteSubmitted: userData?.quoteSubmitted,
                      uw_comments: userData?.uw_comments
                        ? typeof userData?.uw_comments === "string"
                          ? [userData?.uw_comments]
                          : userData?.uw_comments
                        : [""],
                      agent_comments: userData?.agent_comments
                        ? typeof userData.agent_comments === "string"
                          ? [userData?.agent_comments]
                          : userData?.agent_comments
                        : [""],
                      uwStatus: userData?.uw_status,
                      uuidCarriers: sortKeyPrepare,
                      govClassCode: governingClassCode,
                      govCCState: govccstate,
                      selectedPeo: userData?.peoDetails?.selectedPeo,
                      salesforceData: userData?.salesforceData,
                      selectedPeoToDisplay: this.selectedPeoFormat(
                        userData?.peoDetails?.selectedPeo
                      ),
                      pricingStatus:
                        pricingStatusMap?.[userData?.approval_status],
                      miscSalesforceData: userData?.miscSalesforceData,
                      fileList: userData?.fileList ? userData?.fileList : [],
                      deal_won: userData?.deal_won,
                      deal_lost: userData?.deal_lost,
                      deal_confirmed: userData?.deal_confirmed,
                    };
                    _submissionYesNoList[rowData.user_email_id] =
                      userData.real_submission === "true" ? true : false;

                    _agentCommentsHistory[rowData.user_email_id] =
                      userData?.agent_comments
                        ? typeof userData.agent_comments === "string"
                          ? [userData?.agent_comments]
                          : userData?.agent_comments
                        : [""];
                    if (rowData.companyName === "-") continue;
                    if (tabName === "tab1") {
                      if (rowData?.deal_confirmed) {
                        if (rowData?.deal_won) {
                          _wonList.push(rowData);
                        } else if (rowData?.deal_lost) {
                          _lostList.push(rowData);
                        }
                      } else {
                        if (rowData?.deal_won) {
                          _dealMap[rowData.user_email_id] = true;
                        } else if (rowData?.deal_lost) {
                          _dealMap[rowData.user_email_id] = false;
                        }
                        _userDataList.push(rowData);
                      }
                    } else if (tabName === "tab2") {
                      _pricingRequestList.push(rowData);
                    }
                  } catch (error) {
                    console.log("error in: ", userData, error);
                  }
                }
                if (tabName === "tab1") {
                  this.setState({
                    wonLostLoading: false,
                    userDataListLoading: false,
                    userDataList: _userDataList,
                    wonList: _wonList,
                    lostList: _lostList,
                    dealMap: _dealMap,
                  });
                } else {
                  this.setState({
                    pricingRequestListLoading: false,
                    pricingRequestList: _pricingRequestList,
                  });
                }
                if (tabName === "tab1") {
                  this.setState((prevstate) => {
                    let submissionYesNoList = JSON.parse(
                      JSON.stringify(prevstate.submissionYesNoListUserData)
                    );
                    let agentCommentsHistory = JSON.parse(
                      JSON.stringify(prevstate.agentCommentsHistoryUserData)
                    );
                    return {
                      submissionYesNoListUserData: {
                        ..._submissionYesNoList,
                        ...submissionYesNoList,
                      },
                      agentCommentsHistoryUserData: {
                        ..._agentCommentsHistory,
                        ...agentCommentsHistory,
                      },
                    };
                  });
                } else {
                  this.setState((prevstate) => {
                    let submissionYesNoList = JSON.parse(
                      JSON.stringify(
                        prevstate.submissionYesNoListPricingRequest
                      )
                    );
                    let agentCommentsHistory = JSON.parse(
                      JSON.stringify(
                        prevstate.agentCommentsHistoryPricingRequest
                      )
                    );
                    return {
                      submissionYesNoListPricingRequest: {
                        ..._submissionYesNoList,
                        ...submissionYesNoList,
                      },
                      agentCommentsHistoryPricingRequest: {
                        ..._agentCommentsHistory,
                        ...agentCommentsHistory,
                      },
                    };
                  });
                }
                resolve();
              })
              .catch((error) => {
                console.log("error", error);
                reject();
              });
          });
        } while (last_key);

        if (!last_key) {
          if (tabName === "tab1") {
            this.setState({
              wonLostDataLoading: false,
              userDataListDataLoading: false,
            });
          } else {
            this.setState({ pricingRequestListDataLoading: false });
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  selectedPeoFormat = (selectedPeo) => {
    let displayPEOName = selectedPeo?.toLowerCase();
    if (selectedPeo === "insurecomp") {
      displayPEOName = "LIB";
    } else if (selectedPeo === "esg halcyon") {
      displayPEOName = "ESG Halcyon";
    } else if (selectedPeo === "express hr" || selectedPeo === "expresshr") {
      displayPEOName = "Express HR";
    } else if (selectedPeo === "gregory") {
      displayPEOName = "J Gregory";
    } else if (selectedPeo === "totalsolution") {
      displayPEOName = "Total Solution";
    } else if (selectedPeo === "payrollmadeeasy") {
      displayPEOName = "Payroll Made Easy";
    } else if (selectedPeo === "stafflink") {
      displayPEOName = "Prestige";
    }
    displayPEOName = displayPEOName?.replace(/\b\w/g, (char) =>
      char?.toUpperCase()
    );

    // Capitalize entire word if it's only 4 or less than 4 letters
    if (displayPEOName?.length <= 4) {
      displayPEOName = displayPEOName?.toUpperCase();
    }
    return displayPEOName;
  };
  formatPayrollToNumber = (payroll) => {
    // Remove '$' and ',' and convert to number
    return payroll ? parseFloat(payroll.replace(/[^\d.-]/g, "")) : 0;
  };
  camelCase = (str) => {
    if (!str) return "";
    return str
      .trim()
      .split(" ")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  };
  fetchUserDetails = async () => {
    try {
      let currAuthUser = await Auth.currentAuthenticatedUser();
      let email = currAuthUser?.attributes?.email;
      let groups =
        currAuthUser?.signInUserSession?.accessToken?.payload?.[
          "cognito:groups"
        ];

      return {
        isLoggedIn: true,
        data: { name: "", email, groups },
      };
    } catch (error) {
      // Handle the error appropriately
      console.error("Failed to fetch user details:", error);
      return {
        isLoggedIn: false,
        data: { name: "", email: "", groups: [] },
      };
    }
  };
  handleAgentComment = (rowData, data, tabName) => {
    const agentCommentsHistoryMap = {
      tab1: "agentCommentsHistoryUserData",
      tab2: "agentCommentsHistoryPricingRequest",
      tab3: "agentCommentsHistoryUserData",
      tab4: "agentCommentsHistoryUserData",
    };
    let agentCommentsHistoryName = agentCommentsHistoryMap[tabName];

    this.setState((prevstate) => {
      let agentCommentsHistory = JSON?.parse(
        JSON?.stringify(prevstate?.[agentCommentsHistoryName])
      );
      agentCommentsHistory[rowData?.user_email_id] = data;
      if (tabName === "tab2") {
        return { agentCommentsHistoryPricingRequest: agentCommentsHistory };
      } else {
        return { agentCommentsHistoryUserData: agentCommentsHistory };
      }
    });
  };
  updateDealMap = (key, value) => {
    let { dealMap } = this.state;
    if (dealMap[key] === value) {
      delete dealMap[key];
    } else {
      dealMap[key] = value;
    }

    this.setState({ dealMap });
  };
  updateConfirmDealMap = (key) => {
    let { confirmDealMap } = this.state;
    confirmDealMap[key] = true;
    this.setState({ confirmDealMap });
  };
  handleDealConfirmAll = (toMoveList) => {
    let { wonList, lostList, userDataList, pricingRequestList } = JSON.parse(
      JSON.stringify(this.state)
    );

    toMoveList.sort((a, b) => a.index - b.index).reverse();

    for (let data of toMoveList) {
      let { index, dealFlag, rowData, tab } = data;
      rowData.deal_confirmed = true;
      delete rowData?.tableData?.checked;

      if (dealFlag) {
        rowData.deal_won = true;
        rowData.deal_lost = false;
        wonList.unshift(rowData);
      } else {
        rowData.deal_won = false;
        rowData.deal_lost = true;
        lostList.unshift(rowData);
      }
      const userDataIndex = userDataList.findIndex(
        (item) => item.user_email_id === rowData?.user_email_id
      );
      const pricingRequestIndex = pricingRequestList.findIndex(
        (item) => item.user_email_id === rowData?.user_email_id
      );

      if (userDataIndex > -1) {
        userDataList.splice(userDataIndex, 1);
        this.setState({ userDataList });
      }
      if (pricingRequestIndex > -1) {
        pricingRequestList.splice(pricingRequestIndex, 1);
        this.setState({ pricingRequestList });
      }
    }

    this.setState({
      wonList,
      lostList,
    });
  };
  handleDealConfirm = (rowData, dealFlag) => {
    let { wonList, lostList, userDataList, pricingRequestList } = JSON.parse(
      JSON.stringify(this.state)
    );
    const userDataIndex = userDataList.findIndex(
      (item) => item.user_email_id === rowData?.user_email_id
    );
    const pricingRequestIndex = pricingRequestList.findIndex(
      (item) => item.user_email_id === rowData?.user_email_id
    );
    rowData.deal_confirmed = true;

    if (dealFlag) {
      wonList.unshift(rowData);
      rowData.deal_won = true;
      rowData.deal_lost = false;
    } else {
      lostList.unshift(rowData);
      rowData.deal_won = false;
      rowData.deal_lost = true;
    }
    this.setState({ wonList, lostList });
    if (userDataIndex > -1) {
      userDataList.splice(userDataIndex, 1);
      this.setState({ userDataList });
    }
    if (pricingRequestIndex > -1) {
      pricingRequestList.splice(pricingRequestIndex, 1);
      this.setState({ pricingRequestList });
    }
  };

  setSelected = (tab) => {
    let selected = this.state.state;
    if (selected !== tab) {
      this.setState({ selected: tab });
    }
  };

  handleAgentCommentsButtonClick = (comments, submittedDate) => {
    this.setState({ agentButton: true });
    if (typeof comments[0] === "string" && comments[0] !== "") {
      let updatedComment = [
        {
          ...initialcomment,
          comment: comments[0],
          commentTimestamp: submittedDate,
        },
      ];
      this.setState({ modalBody: updatedComment });
    } else if (typeof comments[0] === "object") {
      this.setState({ modalBody: comments });
    } else {
      this.setState({ modalBody: [initialcomment] });
    }

    this.setState({ openModal: true });
  };

  handleUwCommentHistory = (rowdata, rowkey) => {
    this.setState({ agentButton: false });
    rowdata.forEach((item) => {
      if (item.user_email_id === rowkey) {
        if (typeof item.uw_comments?.[0] === "object") {
          this.setState({ modalBody: item.uw_comments });
        } else if (typeof item.agent_comments?.[0] === "string") {
          let updatedComment = [
            {
              ...initialcomment,
              comment: item.uw_comments[0],
              commentTimestamp: item.uploadTimestamp,
            },
          ];
          this.setState({ modalBody: updatedComment });
        }
      }
    });
    this.setState({ openModal: true });
  };

  handleCloseModal = () => {
    this.setState({ openModal: false });
  };

  render() {
    let {
      selected,
      modalBody,
      openModal,
      agentButton,
      loggedInUserDetails,
      userDataList,
      pricingRequestList,
      wonList,
      lostList,
      userDataListLoading,
      userDataListDataLoading,
      pricingRequestListLoading,
      pricingRequestListDataLoading,
      wonLostLoading,
      wonLostDataLoading,
      tabsMap,
      submissionYesNoListUserData,
      agentCommentsHistoryUserData,
      agentCommentsHistoryPricingRequest,
      submissionYesNoListPricingRequest,
    } = this.state;
    const rowDataListMapping = {
      tab1: userDataList,
      tab2: pricingRequestList,
      tab3: wonList,
      tab4: lostList,
    };

    const isLoadingMap = {
      tab1: userDataListLoading,
      tab2: pricingRequestListLoading,
      tab3: wonLostLoading,
      tab4: wonLostLoading,
    };
    const dataLoadingMap = {
      tab1: userDataListDataLoading,
      tab2: pricingRequestListDataLoading,
      tab3: wonLostDataLoading,
      tab4: wonLostDataLoading,
    };
    const agentCommentsHistoryMap = {
      tab1: agentCommentsHistoryUserData,
      tab2: agentCommentsHistoryPricingRequest,
      tab3: agentCommentsHistoryUserData,
      tab4: agentCommentsHistoryUserData,
    };
    const submissionYesNoListMap = {
      tab1: submissionYesNoListUserData,
      tab2: submissionYesNoListPricingRequest,
      tab3: submissionYesNoListUserData,
      tab4: submissionYesNoListUserData,
    };

    return (
      <div className="dashboard-container">
        <div className="d-flex">
          <Header />
        </div>

        <div className="position-relative">
          {isMobile_sidenav ? <SideTab /> : " "}

          <div className="tabs  lib_dashboard">
            <Nav
              className="userdashboard__button gap-2"
              variant="pills"
              defaultActiveKey={selected}
              style={{ fontSize: "16px" }}
            >
              {Object.keys(tabsMap).map((tab) => (
                <Nav.Item key={tab}>
                  <Nav.Link
                    eventKey={tab}
                    onClick={() => this.setSelected(tab)}
                    style={
                      tab === selected
                        ? { backgroundColor: "rgb(179, 75, 62)" }
                        : { color: "rgb(179, 75, 62)" }
                    }
                  >
                    {tabsMap[tab]}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
            {Object.keys(tabsMap).map((tab) => (
              <div key={tab} className="Admin__Dashboard">
                {loggedInUserDetails?.data?.groups?.length > 0 && (
                  <LibertateDashboard
                    loggedInGroups={loggedInUserDetails?.data?.groups}
                    tabName={tab}
                    selected={selected}
                    modalBody={modalBody}
                    openModal={openModal}
                    agentButton={agentButton}
                    handleAgentCommentsButtonClick={
                      this.handleAgentCommentsButtonClick
                    }
                    handleUwCommentHistory={this.handleUwCommentHistory}
                    handleCloseModal={this.handleCloseModal}
                    updateDealMap={this.updateDealMap}
                    dealMap={this.state.dealMap}
                    updateConfirmDealMap={this.updateConfirmDealMap}
                    handleDealConfirm={this.handleDealConfirm}
                    handleDealConfirmAll={this.handleDealConfirmAll}
                    confirmDealMap={this.state.confirmDealMap}
                    rowDataList={rowDataListMapping[tab]}
                    isLoading={isLoadingMap[tab]}
                    dataLoading={dataLoadingMap[tab]}
                    agentCommentsHistory={agentCommentsHistoryMap[tab]}
                    submissionYesNoList={submissionYesNoListMap[tab]}
                    camelCase={this.camelCase}
                    handleAgentComment={this.handleAgentComment}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
        <Loader />
      </div>
    );
  }
}

class TextField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      loggedInEmail: "",
    };
  }
  onChangeHandler(target) {
    this.setState({ value: target });
  }

  componentDidMount() {
    this.loggedInEmailId();
  }

  loggedInEmailId = async () => {
    try {
      let sessionData = await Auth.currentAuthenticatedUser();
      // console.log("++sd", sessionData);
      this.setState({
        loggedInEmail: sessionData["attributes"]["email"],
      });
    } catch (error) {
      console.error("Error fetching authenticated user:", error);
    }
  };

  render() {
    let { value, loggedInEmail } = this.state;
    return (
      <textarea
        value={value}
        className="underwriter_comment"
        rows="1"
        style={{
          width: "100%",
          height: "23px",
          // backgroundColor: setCommentBoxColor(this.props.comments),
        }}
        onChange={(e) => this.onChangeHandler(e.target.value)}
        onBlur={(e) => {
          let agentCommentsCopy = this.props.agentCommentsHistory;

          let currentTimestamp = moment().valueOf();
          let dataToSave = {};

          if (agentCommentsCopy === undefined && e.target.value.trim() !== "") {
            agentCommentsCopy = [
              {
                comment: e.target.value.trim(),
                commentTimestamp: currentTimestamp,
                email: loggedInEmail,
              },
            ];
          } else if (
            e.target.value.length === 0 ||
            e.target.value.trim() === ""
          ) {
            if (
              agentCommentsCopy[0]?.comment &&
              e.target.value.trim() !== agentCommentsCopy[0]?.comment
            ) {
              agentCommentsCopy.unshift({
                comment: e.target.value.trim(),
                commentTimestamp: currentTimestamp,
                previousComment: "true",
                email: loggedInEmail,
              });
            } else if (
              agentCommentsCopy[0] &&
              typeof agentCommentsCopy[0] === "string"
            ) {
              e.target.value.trim() !== agentCommentsCopy[0] &&
                agentCommentsCopy.unshift({
                  comment: e.target.value.trim(),
                  commentTimestamp: currentTimestamp,
                  previousComment: "true",
                  email: loggedInEmail,
                });
            } else {
              console.log("I m returning as value is 0");
              return;
            }
          } else if (typeof agentCommentsCopy[0] === "string") {
            e.target.value.trim() !== agentCommentsCopy[0] &&
              agentCommentsCopy.unshift({
                comment: e.target.value.trim(),
                commentTimestamp: currentTimestamp,
                email: loggedInEmail,
              });
          } else {
            e.target.value.trim() !== agentCommentsCopy[0]?.comment &&
              agentCommentsCopy.unshift({
                comment: e.target.value.trim(),
                commentTimestamp: currentTimestamp,
                email: loggedInEmail,
              });
          }

          this.props.handleAgentCommentHistory(
            this.props.rowData,
            agentCommentsCopy
          );
          dataToSave.user_email_id = this.props.rowData.user_email_id;
          dataToSave.sortKeyList = this.props.rowData.uuidCarriers;
          dataToSave.agent_comments = agentCommentsCopy;
          dataToSave.agent_comments && this.props.callback(dataToSave);
        }}
      ></textarea>
    );
  }
}
let initialcomment = {
  comment: "",
  commentTimestamp: "",
  email: "",
};
class LibertateDashboard extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      scrollLeft: false,
      scrollRight: true,
      popupData: {
        title: "",
        children: <></>,
        acceptBtn: "",
        acceptBtnCallback: () => {},
        rejectBtnCallback: () => {},
        show: false,
      },
      modalData: {},
      selectedRows: [],
      confirmAllLoading: false,
    };
  }

  componentDidMount = () => {
    sessionStorage.clear();
  };

  onClickCompanyName = (rowData) => {
    sessionStorage.clear();
    console.log(rowData);
    if (rowData.amtrustAccountDetails) {
      sessionStorage.setItem(
        "amtrustAccountDetails",
        JSON.stringify(rowData.amtrustAccountDetails)
      );
    }
    if (rowData?.deal_confirmed) {
      sessionStorage.setItem("dealConfirmed", true);
    }
    let currProspect = {
      childrenLoc: rowData.childrenLoc,
      companyProfile: rowData.companyProfile,
      emodStatesData: rowData.emodStatesData,
      riskProfile: rowData.riskProfile,
      peoDetails: rowData.peoDetails,
      carrierList: rowData.carrierList,
    };

    if (rowData.uw_ques) {
      currProspect["uwQues"] = rowData.uw_ques;
    }

    if (rowData.salesforceData) {
      sessionStorage.setItem(
        "salesforceData",
        JSON.stringify(rowData.salesforceData)
      );
    }

    if (rowData.miscSalesforceData) {
      sessionStorage.setItem(
        "miscSalesforceData",
        JSON.stringify(rowData.miscSalesforceData)
      );
    }

    if (
      rowData.status === "quote_generated" ||
      rowData.status === "view_proposal"
    ) {
      sessionStorage.setItem("quoteData", JSON.stringify(rowData.quoteData));
      sessionStorage.setItem("address", JSON.stringify(rowData.address));
      sessionStorage.setItem("formStage", "three");
      if (rowData.quoteSubmitted) {
        sessionStorage.setItem("hideButtons", "true");
      }
    } else if (rowData.status === "company_profile") {
      sessionStorage.setItem("formStage", "two");
    }

    if (rowData.status === "view_proposal") {
      sessionStorage.setItem("formStage", "four");
    }

    if (rowData?.lossRunUploaded) {
      sessionStorage.setItem("uploadLossRun", true);
    }

    sessionStorage.setItem("currProspect", JSON.stringify(currProspect));
    sessionStorage.setItem("user_id", rowData.user_email_id);

    window.location.href = "/Quote";
  };

  updateData = (data) => {
    new Promise((resolve, reject) => {
      try {
        axios
          .post(awsUrl + "/api/underWriterData/update", data)
          .then((res) => {
            console.log("data saved");
            resolve();
          })
          .catch((err) => {
            console.log("error while saving", err);
            reject();
          });
      } catch (error) {}
    });
  };

  updateDataInUser = (data) => {
    new Promise((resolve, reject) => {
      try {
        let data_req = JSON.parse(JSON.stringify(data));
        if (data_req.sortKeyList) {
          delete data_req.sortKeyList;
        }
        axios
          .post(awsUrl2 + "/api/userTableData/update", data_req)
          .then((res) => {
            console.log("data saved");
            resolve();
          })
          .catch((err) => {
            console.log("error while saving", err);
            reject();
          });
      } catch (error) {}
    });
  };

  dateSort = (a, b, field) => {
    let momentA = moment(a[field], "M/D/YY");
    let momentB = moment(b[field], "M/D/YY");

    if (
      (!momentA.isValid() && momentB.isValid()) ||
      momentA.isBefore(momentB)
    ) {
      return -1;
    } else if (
      (momentA.isValid() && !momentB.isValid()) ||
      momentA.isAfter(momentB)
    ) {
      return 1;
    } else {
      return 0;
    }
  };

  customFilterAll = (term, rowData, field) => {
    let customFieldName = field["field"];

    return rowData[customFieldName]
      ?.toString()
      ?.toLowerCase()
      ?.startsWith(term?.toLowerCase());
  };

  scrollLeft = () => {
    let { scrollLeft, scrollRight } = JSON.parse(JSON.stringify(this.state));
    const tableContainerDiv =
      this.myRef.current.lastChild.lastChild.children[2].children[0]
        .children[0];

    if (tableContainerDiv) {
      tableContainerDiv.scrollLeft -= 150;
      if (tableContainerDiv.scrollLeft == 0) {
        this.setState({ scrollLeft: false });
        this.setState({ scrollRight: true });
      }

      const maxScrollLeft =
        tableContainerDiv.scrollWidth - tableContainerDiv.clientWidth;

      if (tableContainerDiv.scrollLeft < maxScrollLeft) {
        this.setState({ scrollRight: true });
      }
    }
  };

  scrollRight = () => {
    let { scrollLeft, scrollRight } = JSON.parse(JSON.stringify(this.state));

    const tableContainerDiv =
      this.myRef.current.lastChild.lastChild.children[2].children[0]
        .children[0];

    if (tableContainerDiv) {
      tableContainerDiv.scrollLeft += 150;
      this.setState({ scrollLeft: true });
    }

    const maxScrollLeft =
      tableContainerDiv.scrollWidth - tableContainerDiv.clientWidth;

    if (tableContainerDiv.scrollLeft > maxScrollLeft) {
      this.setState({ scrollRight: false });
    }
  };
  fontfunciton = () => {
    if (window.innerWidth < 1600 && window.innerWidth > 1500) {
      return "0.84rem";
    }
    if (window.innerWidth < 1500 && window.innerWidth > 1410) {
      return "0.8rem";
    }
    if (window.innerWidth < 1410 && window.innerWidth > 1310) {
      return "0.76rem";
    }
    if (window.innerWidth < 1300) {
      return "0.72rem";
    }
  };

  customFilterAll = (term, rowData, field) => {
    let customFieldName = field["field"];

    return rowData[customFieldName]
      ?.toString()
      ?.toLowerCase()
      ?.startsWith(term?.toLowerCase());
  };
  formatFein = (value) => {
    if (!value) return "-";
    let fein = "-";
    value = value?.split("-")?.join("");
    var len = value?.length,
      dummystr = "000000000";

    if (len === 0) {
      fein = "-";
    } else if (len > 0 && len < 9) {
      value = dummystr.slice(0, 9 - len) + "" + value;
      fein = value[0] + value[1] + "-" + value?.slice(2);
    } else {
      fein = value[0] + value[1] + "-" + value?.slice(2);
    }

    return fein;
  };

  handleAgentCommentHistory = (rowData, data) => {
    this.props.handleAgentComment(rowData, data, this.props.tabName);
    // this.setState((prevstate) => {
    //   console.log('prevstateprevstate', prevstate, prevstate?.agentCommentsHistory);

    //   let agentCommentsHistory = JSON?.parse(
    //     JSON?.stringify(prevstate?.agentCommentsHistory)
    //   );
    //   agentCommentsHistory[rowData?.user_email_id] = data;
    //   return { agentCommentsHistory: agentCommentsHistory };
    // });
  };

  // toggleSwitchHandlerInDashboard = async (valueCheck, rowData) => {
  //   let value = valueCheck ? "false" : "true";
  //   let req_body_userstatus = {
  //     user_email_id: rowData?.user_email_id,
  //     real_submission: value,
  //     address: rowData?.address,
  //     timestamp: rowData?.quoteData?.date,
  //     uuid: rowData?.quoteData?.uuid,
  //     peo: rowData.peoDetails.selectedPeo,
  //   };
  //   let req_body_users = {
  //     user_email_id: rowData?.user_email_id,
  //     real_submission: value,
  //   };
  //   let result1 = saveInUserstatusTable(req_body_userstatus);
  //   let result2 = saveInUserTable(req_body_users);

  //   Promise.all([result1, result2])
  //     .then(([result1, result2]) => {})
  //     .catch((err) => {
  //       console.log(err);
  //     });

  //   this.setState((prevstate) => {
  //     let submissionYesNoList = JSON.parse(
  //       JSON.stringify(prevstate.submissionYesNoList)
  //     );
  //     submissionYesNoList[rowData?.user_email_id] = valueCheck ? false : true;
  //     return { submissionYesNoList: submissionYesNoList };
  //   });
  // };

  setModalData = (data) => {
    this.setState({ modalData: data });
  };

  handleSelection = (rows) => {
    this.setState({ selectedRows: rows });
    // console.log(selectedRows.length);
  };
  handleConfirmAll = (tabName) => {
    let { dealMap } = this.props;
    let { selectedRows } = this.state;
    if (selectedRows.length > 0) {
      this.updatePopUpData({
        show: true,
        title: "Do you wish to confirm the deals?",
        children: (
          <>
            <br></br>
            <span style={{ fontWeight: "bold" }}>
              This action will move the submission to the respective tab.
            </span>
          </>
        ),
        acceptBtn: "Yes",
        acceptBtnCallback: async () => {
          try {
            let promiseList = [];
            let confirmAllDataList = [];
            let i = 1;
            this.setState({ confirmAllLoading: true });

            for (let row of selectedRows) {
              let { user_email_id, quoteData, address } = row;

              let key = user_email_id;
              if (
                (dealMap[key] === true || dealMap[key] === false) &&
                (!row.deal_confirmed || row?.deal_confirmed !== true) &&
                (row?.pricingStatus === "Approved" ||
                  row?.pricingStatus === "Rejected" ||
                  row?.pricingStatus === "Submitted")
              ) {
                let dealFlag;
                if (dealMap[key] === true) {
                  dealFlag = true;
                } else if (dealMap[key] === false) {
                  dealFlag = false;
                } else {
                  dealFlag = row.deal_won;
                }

                confirmAllDataList.push({
                  tab: tabName,
                  index: Number(row.tableData.id),
                  dealFlag,
                  rowData: row,
                  key: row?.user_email_id,
                  deal_confirmed: true,
                });

                let userRequestBody = {
                  user_email_id,
                  deal_confirmed: true,
                };

                promiseList.push(
                  axios.post(
                    awsUrl2 + "/api/userTableData/update",
                    userRequestBody
                  )
                );
                if (quoteData) {
                  let { uuid, date } = quoteData;
                  let quoteRequestBody = {
                    user_email_id,
                    timestamp: date,
                    peo: "ies",
                    address,
                    uuid,
                    deal_confirmed: true,
                  };
                  promiseList.push(
                    axios.post(
                      awsUrl2 + "/api/updateUserStatusTable",
                      quoteRequestBody
                    )
                  );
                }

                if (i % 10 === 0) {
                  await Promise.all(promiseList);
                  promiseList = [];
                }
                i++;
              }
            }
            if (promiseList.length > 0) {
              await Promise.all(promiseList);
            }
            this.setState({ confirmAllLoading: false });
            if (this.props.handleDealConfirmAll) {
              this.props.handleDealConfirmAll(confirmAllDataList);
            }
            for (let data of confirmAllDataList) {
              this.props.updateConfirmDealMap(data.key);
            }
          } catch (error) {
            console.log("error: ", error);
          }
        },
      });
    } else {
      this.updatePopUpData({
        show: true,
        title: "Nothing to confirm.",
        children: (
          <>
            <br></br>
            <span style={{ fontWeight: "bold" }}>
              Please select rows to confirm deals.
            </span>
          </>
        ),
        acceptBtnCallback: async () => {},
        disableNoButton: true,
      });
    }
  };
  setTableData = () => {
    let { scrollLeft, scrollRight } = JSON.parse(JSON.stringify(this.state));
    let {
      loggedInGroups,
      updateDealMap,
      dealMap,
      updateConfirmDealMap,
      handleDealConfirm,
      confirmDealMap,
      rowDataList,
      isLoading,
      submissionYesNoList,
      agentCommentsHistory,
      tabName,
      handleAgentCommentsButtonClick,
      handleUwCommentHistory,
      camelCase,
    } = this.props;

    const defaultMaterialTheme = createTheme();

    let columns = [
      {
        title: "Company Name",
        field: "companyName",
        filtering: true,
        filterPlaceholder: "Filter",

        cellStyle: {
          padding: "0px 0px 0px 5px",
          minWidth: "180px",
          maxWidth: "350px",
        },
        headerStyle: {
          padding: " 5px 5px 0px 5px",
          minWidth: "180px",
          maxWidth: "380px",
          backgroundColor: "rgb(179 75 62)",
        },
        filterCellStyle: {
          padding: "0px 0px 0px 0px",
        },

        render: (rowData) => (
          <Clamp lines={2}>
            <span>
              <div
                onClick={() => this.onClickCompanyName(rowData)}
                className="company-name"
              >
                {camelCase(rowData.companyName)}
              </div>
            </span>
          </Clamp>
        ),
      },
      {
        title: "FEIN",
        field: "fein",
        filterPlaceholder: "Filter",
        customFilterAndSearch: (term, rowData, field) =>
          this.customFilterAll(term, rowData, field),

        cellStyle: {
          wordBreak: "break-all",
          padding: " 0px 0px 0px 5px",
          minWidth: "100px",
        },
        headerStyle: {
          padding: " 5px 5px 0px 5px",
          minWidth: "100px",
          backgroundColor: "rgb(179 75 62)",
        },
        filterCellStyle: {
          padding: "0px 0px 0px 0px",
        },

        render: (rowData) => (
          <Clamp lines={1}>{this.formatFein(rowData?.fein) || ""}</Clamp>
        ),
      },
      // {
      //   title: "Contact Name",
      //   field: "contactName",
      //   filtering: true,
      //   filterPlaceholder: "Filter",
      //   cellStyle: {
      //     padding: "6px 15px 6px 10px",
      //     minWidth: "200px",
      //     maxWidth: "300px",
      //   },
      //   headerStyle: {
      //     padding: " 0px 0px 0px 10px",
      //     minWidth: "200px",
      //     maxWidth: "300px",
      //   },
      //   render: (rowData) => <Clamp lines={1}>{rowData.contactName}</Clamp>,
      // },
      {
        title: "Gov CC State",
        field: "govCCState",
        filtering: true,
        filterPlaceholder: "Filter",
        cellStyle: {
          padding: " 0px 0px 0px 5px",
          minWidth: "90px",
          width: "90px",
        },
        headerStyle: {
          padding: " 5px 5px 0px 5px",
          minWidth: "90px",
          width: "90px",
          backgroundColor: "rgb(179 75 62)",
        },
        filterCellStyle: {
          padding: "0px 0px 0px 0px",
        },

        render: (rowData) => (
          <Clamp lines={1}>{rowData.govCCState || "-"}</Clamp>
        ),
      },
      {
        title: "Primary State",
        field: "state",
        filtering: true,
        filterPlaceholder: "Filter",
        cellStyle: {
          padding: " 0px 0px 0px 5px",
          minWidth: "60px",
          width: "60px",
        },
        headerStyle: {
          padding: " 5px 5px 0px 5px",
          minWidth: "60px",
          width: "60px",
          backgroundColor: "rgb(179 75 62)",
        },
        filterCellStyle: {
          padding: "0px 0px 0px 0px",
        },

        render: (rowData) => <Clamp lines={1}>{rowData.state || "-"}</Clamp>,
      },
      {
        title: "Class Code",
        field: "govClassCode",
        filtering: true,
        filterPlaceholder: "Filter",
        cellStyle: {
          padding: " 0px 0px 0px 5px",
          minWidth: "40px",
          width: "40px",
        },
        headerStyle: {
          padding: " 5px 5px 0px 5px",
          minWidth: "40px",
          width: "40px",
          backgroundColor: "rgb(179 75 62)",
        },
        filterCellStyle: {
          padding: "0px 0px 0px 0px",
        },

        render: (rowData) => (
          <Clamp lines={1}>{rowData.govClassCode || "-"}</Clamp>
        ),
      },
      {
        title: "Payroll",
        field: "payroll",
        filtering: true,
        filterPlaceholder: "Filter",
        cellStyle: {
          padding: " 0px 0px 0px 5px",
          minWidth: "120px",
          textAlign: "right",
        },
        headerStyle: {
          padding: " 5px 5px 0px 5px",
          minWidth: "120px",
          backgroundColor: "rgb(179 75 62)",
          textAlign: "left",
        },
        filterCellStyle: {
          padding: "0px 0px 0px 0px",
        },

        render: (rowData) => (
          <div className="payrollBox">
            <Clamp lines={1}>{rowData.payroll}</Clamp>
          </div>
        ),
      },
      // {
      //   title: "Phone Number",
      //   field: "phoneNumber",
      //   filtering: true,
      //   filterPlaceholder: "Filter",
      //   render: (rowData) =>
      //     rowData.phoneNumber
      //       ? rowData.phoneNumber.length == 10
      //         ? rowData.phoneNumber.replace(
      //             /(\d{3})(\d{3})(\d{4})/,
      //             "($1) $2-$3"
      //           )
      //         : rowData.phoneNumber.replace(
      //             /(\d{3})(\d{3})(\d{3})/,
      //             "($1) $2-$3"
      //           )
      //       : "-",
      //   cellStyle: {
      //     padding: "6px 15px 6px 10px",
      //     minWidth: "150px",
      //     width: "150px",
      //   },
      //   headerStyle: {
      //     padding: " 0px 0px 0px 10px",
      //     minWidth: "150px",
      //   },
      // },
      {
        title: "Effective Date",
        field: "effectiveDate",
        filtering: true,
        filterPlaceholder: "Filter",
        render: (rowData) => rowData.effectiveDate,

        headerStyle: {
          padding: " 5px 5px 0px 5px",
          minWidth: "80px",
          backgroundColor: "rgb(179 75 62)",
        },
        cellStyle: {
          padding: " 0px 0px 0px 5px",
          minWidth: "75px",
          width: "75px",
        },
        filterCellStyle: {
          padding: "0px 0px 0px 0px",
        },

        customFilterAndSearch: (term, rowData, field) =>
          this.customFilterAll(term, rowData, field),
      },
      // {
      //   title: "Distributing Partners",
      //   field: "selectedPeoToDisplay",
      //   filtering: true,
      //   filterPlaceholder: "Filter",
      //   cellStyle: {
      //     padding: "2px 0px 2px 6px",
      //     minWidth: "120px",
      //   },
      //   headerStyle: {
      //     padding: " 0px 0px 0px 10px",
      //     minWidth: "120px",
      //     backgroundColor: "rgb(179 75 62)",
      //   },

      //   render: (rowData) => (
      //     <Clamp lines={1}>{rowData.selectedPeoToDisplay || "-"}</Clamp>
      //   ),
      // },
      {
        title: "Modified By",
        field: "modifiedBy",
        filtering: true,
        filterPlaceholder: "Filter",
        render: (rowData) => (
          <Clamp lines={1}>
            {rowData.modifiedBy
              ? rowData.modifiedBy.split("@")[0].length <= 3
                ? rowData.modifiedBy.split("@")[0].toUpperCase()
                : rowData.modifiedBy.split("@")[0][0].toUpperCase() +
                  rowData.modifiedBy.split("@")[0].slice(1)
              : ""}
          </Clamp>
        ),

        headerStyle: {
          padding: " 5px 5px 0px 5px",
          minWidth: "80px",
          backgroundColor: "rgb(179 75 62)",
        },
        cellStyle: {
          padding: " 0px 0px 0px 5px",
          minWidth: "80px",
          width: "80px",
        },
        filterCellStyle: {
          padding: "0px 0px 0px 0px",
        },
      },
      {
        title: "Status",
        field: "step",
        filtering: true,
        filterPlaceholder: "Filter",
        // cellStyle: (e, rowData) => {
        //   let style = { padding: "6px 15px 6px 10px", minWidth: "110px" };
        //   let stepValue = rowData?.uwStatus || rowData?.step;
        //   if (stepValue === "Quote Generated") {
        //     style.backgroundColor = "#87FA4D";
        //   } else if (stepValue === "rejected") {
        //     style.backgroundColor = "#cb2e3e";
        //   } else if (stepValue === "onHold") {
        //     style.backgroundColor = "#ffbd16";
        //   } else if (stepValue === "Underwriting Page") {
        //     style.backgroundColor = "#fa6d3e";
        //   } else {
        //     style.backgroundColor = "#FFF";
        //   }
        //   return style;
        // },
        cellStyle: {
          padding: " 0px 0px 0px 5px",
          minWidth: "125px",
        },
        headerStyle: {
          padding: " 5px 5px 0px 5px",
          minWidth: "110px",
          backgroundColor: "rgb(179 75 62)",
        },
        filterCellStyle: {
          padding: "0px 0px 0px 0px",
        },

        render: (rowData) => (
          <Clamp lines={1}>
            {rowData.uwStatus ||
              (rowData.quoteSubmitted ? "Submitted" : "") ||
              rowData.step}
          </Clamp>
        ),
      },
      {
        title: "Pricing Status",
        field: "pricingStatus",
        filtering: true,
        filterPlaceholder: "Filter",
        cellStyle: {
          padding: " 0px 0px 0px 5px",
          minWidth: "125px",
        },
        headerStyle: {
          padding: " 5px 5px 0px 5px",
          minWidth: "110px",
          backgroundColor: "rgb(179 75 62)",
        },
        filterCellStyle: {
          padding: "0px 0px 0px 0px",
        },

        render: (rowData) => (
          <Clamp lines={1}>{rowData?.pricingStatus || ""}</Clamp>
        ),
      },
      ...(this.props.loggedInGroups?.includes(process.env.REACT_APP_ADMIN_GROUP)
        ? [
            {
              title: "Created Date",
              field: "createdDate",
              customSort: (a, b) => this.dateSort(a, b, "createdDate"),
              filtering: true,
              filterPlaceholder: "Filter",
              render: (rowData) => rowData.createdDate,

              headerStyle: {
                padding: " 5px 5px 0px 5px",
                minWidth: "80px",
                backgroundColor: "rgb(179 75 62)",
              },
              cellStyle: {
                padding: " 0px 0px 0px 5px",
                minWidth: "75px",
                width: "75px",
              },
              filterCellStyle: {
                padding: "0px 0px 0px 0px",
              },

              customFilterAndSearch: (term, rowData, field) =>
                this.customFilterAll(term, rowData, field),
            },
            {
              title: "Modified Date",
              field: "uploadTimestamp",
              filtering: true,
              filterPlaceholder: "Filter",

              customSort: (a, b) => this.dateSort(a, b, "uploadTimestamp"),
              render: (rowData) => rowData.uploadTimestamp,

              headerStyle: {
                padding: " 5px 5px 0px 5px",
                minWidth: "80px",
                backgroundColor: "rgb(179 75 62)",
              },
              cellStyle: {
                padding: " 0px 0px 0px 5px",
                minWidth: "75px",
                width: "75px",
              },
              filterCellStyle: {
                padding: "0px 0px 0px 0px",
              },

              customFilterAndSearch: (term, rowData, field) =>
                this.customFilterAll(term, rowData, field),
            },
          ]
        : []),

      // {
      //   title: "UW Status",
      //   field: "uwStatus",
      //   filtering: true,
      //   filterPlaceholder: "Filter",
      //   render: (rowData) => this.camelCase(rowData.uwStatus),
      //   cellStyle: (e, rowData) => {
      //     let style = { padding: "6px 15px 6px 10px" };

      //     if (rowData.uwStatus === "approved") {
      //       style.backgroundColor = "#008e1b";
      //     } else if (rowData.uwStatus === "rejected") {
      //       style.backgroundColor = "#cb2e3e";
      //     } else if (rowData.uwStatus === "onHold") {
      //       style.backgroundColor = "#ffbd16"
      //     } else {
      //       style.backgroundColor = "#FFF";
      //     }

      //     return style;
      //   },
      //   headerStyle: {
      //     padding: " 0px 15px 0px 10px",
      //     minWidth: "100px",
      //   },
      //   cellStyle: {
      //     padding: "6px 15px 6px 10px",
      //     minWidth: "150px",
      //     width: "150px",
      //   },
      //   filterCellStyle: {
      //     padding: "5px 3px 5px 4px",
      //   }
      // },
      // {
      //   title: "UW Comment",
      //   field: "uwComments",
      //   filtering: true,
      //   filterPlaceholder: "Filter",
      //   render: (rowData) => {
      //     return (
      //       <div className="d-flex">
      //         <textarea
      //           value={
      //             typeof rowData?.uw_comments?.[0] === "object"
      //               ? rowData?.uw_comments?.[0]?.comment
      //               : rowData?.uw_comments?.[0] || rowData?.uw_comments || ""
      //           }
      //           className="underwriter_comment"
      //           rows="1"
      //           disabled
      //           style={{
      //             width: "100%",
      //             backgroundColor: setCommentBoxColor(rowData?.uw_comments),
      //           }}
      //         ></textarea>
      //         <Tooltip title="Click to view comment history" arrow>
      //           <button
      //             className="comment-btn"
      //             onClick={
      //               () =>
      //                 handleUwCommentHistory(
      //                   [rowData],
      //                   `${rowData?.user_email_id}`
      //                 )
      //               // console.log([rowData], `${rowData.user_email_id}`)
      //             }
      //           >
      //             ...
      //           </button>
      //         </Tooltip>
      //       </div>
      //     );
      //   },
      //   headerStyle: {
      //     padding: " 0px 0px 0px 21px",
      //     minWidth: "240px",
      //     backgroundColor: "rgb(179 75 62)",
      //   },
      //   filterCellStyle: {
      //     padding: "5px 5px 5px 14px",
      //   },
      //   cellStyle: {
      //     padding: "2px 9px 2px 16px",
      //     minWidth: "240px",
      //     width: "240px",
      //   },
      // },
      {
        title: "Comments",
        field: "agent_comments",
        filtering: true,
        filterPlaceholder: "Filter",
        headerStyle: {
          padding: " 5px 5px 0px 5px",
          minWidth: "240px",
          backgroundColor: "rgb(179 75 62)",
        },
        filterCellStyle: {
          padding: "0px 0px 0px 0px",
        },

        cellStyle: {
          padding: " 0px 0px 0px 5px",
          minWidth: "240px",
          width: "240px",
        },
        render: (rowData) => {
          const callbackFunction = (data) => {
            console.log("inside callback", data);
            console.log(rowData);
            this.updateData(data);
            this.updateDataInUser(data);
          };
          return (
            <div className="d-flex">
              <TextField
                key={`${rowData?.user_email_id}_comment`}
                value={
                  typeof agentCommentsHistory[rowData?.user_email_id]?.[0] ===
                  "object"
                    ? agentCommentsHistory[rowData?.user_email_id]?.[0]?.comment
                    : agentCommentsHistory[rowData?.user_email_id]?.[0]
                }
                callback={callbackFunction}
                rowData={rowData}
                agentCommentsHistory={
                  agentCommentsHistory[rowData?.user_email_id]
                }
                handleAgentCommentHistory={this.handleAgentCommentHistory}
                comments={agentCommentsHistory[rowData?.user_email_id]}
              />
              <Tooltip
                title="Click to view comment history"
                style={{
                  height: "20px",
                  backgroundColor: setCommentBoxColor(
                    agentCommentsHistory[rowData?.user_email_id]
                  ),
                }}
                arrow
              >
                <button
                  className="comment-btn"
                  onClick={() =>
                    handleAgentCommentsButtonClick(
                      agentCommentsHistory[rowData?.user_email_id],
                      rowData?.uploadTimestamp
                    )
                  }
                >
                  ...
                </button>
              </Tooltip>
            </div>
          );
        },
      },
      {
        title: "Download",
        sorting: false,
        disableSortBy: true,
        cellStyle: {
          padding: " 0px 0px 0px 0px",
          minWidth: "55px",
          textAlign: "center",
        },
        headerStyle: {
          textAlign: "right",
          padding: " 0px 0px 0px 0px",
          backgroundColor: "rgb(179 75 62)",
          textAlign: "center",
        },
        filterCellStyle: {
          padding: "0px 0px 0px 0px",
        },

        render: (rowData) => {
          let title = "";

          if (rowData?.fileList?.length) {
            for (let file of rowData?.fileList) {
              let fileToDisplay = file?.split("/");
              title =
                title + fileToDisplay[fileToDisplay?.length - 1] + " &#013; ";
            }
          }

          return (
            <button
              className="btn"
              style={{ padding: "0px" }}
              type="button"
              title={parse(title)}
              data-html="true"
              onClick={() => {
                this.setModalData({
                  showModel: true,
                  modalTitle: <h1>{"Download"}</h1>,
                  modalContent: (
                    <>
                      <DownloadDashboardFiles
                        fileListToStore={rowData?.fileList}
                        summaryData={rowData?.lossSummaryData?.data?.key}
                        companyName={rowData?.companyName}
                      />
                    </>
                  ),
                });
              }}
            >
              <img
                src={downloadimage}
                alt=""
                style={
                  rowData?.fileList?.length || rowData?.lossSummaryData
                    ? {
                        width: "25px",
                        filter: "grayscale(0)",
                      }
                    : {
                        width: "25px",
                        filter: "grayscale(100%)  opacity(0.4)",
                      }
                }
              />
            </button>
          );
        },
      },
      // {
      //   title: "Submission?",
      //   field: "Submission",
      //   filtering: true,
      //   filterPlaceholder: "Filter",
      //   sorting: false,
      //   cellStyle: {
      //     padding: "0px 0px 0px 8px",
      //     minWidth: "80px",
      //     maxWidth: "80px",
      //   },
      //   headerStyle: {
      //     padding: "2px 10px 0px 8px",
      //     minWidth: "80px",
      //     maxWidth: "80px",
      //     backgroundColor: "rgb(179 75 62)",
      //   },
      //   filterCellStyle: {
      //     padding: "2px 0px 5px 4px",
      //   },
      //   render: (rowData) => (
      //     <Clamp lines={1}>
      //       <span>
      //         <ToggleSwitch
      //           id={rowData?.user_email_id}
      //           toggleSwitchCheck={submissionYesNoList[rowData.user_email_id]}
      //           toggleSwitchHandler={() => {
      //             this.toggleSwitchHandlerInDashboard(
      //               submissionYesNoList[rowData.user_email_id],
      //               rowData
      //             );
      //           }}
      //         />
      //       </span>
      //     </Clamp>
      //   ),
      //   customFilterAndSearch: (term, rowData) => {
      //     try {
      //       if (term.toLowerCase() == "yes") {
      //         return submissionYesNoList[rowData.user_email_id]
      //           ?.toString()
      //           ?.toLowerCase()
      //           ?.startsWith(true);
      //       }
      //       if (term.toLowerCase() == "no") {
      //         return submissionYesNoList[rowData.user_email_id]
      //           ?.toString()
      //           ?.toLowerCase()
      //           ?.startsWith(false);
      //       }
      //     } catch (err) {
      //       console.log("error", err);
      //     }
      //   },
      // },
    ];

    if (loggedInGroups.includes(process.env.REACT_APP_SF_SYNC_GROUP)) {
      columns.push({
        title: "Salesforce",
        field: "salesforce",
        sorting: false,
        filtering: false,
        headerStyle: {
          backgroundColor: "rgb(179 75 62)",
          padding: " 0px 0px 0px 0px",
          minWidth: "70px",
          textAlign: "center",
        },
        cellStyle: {
          padding: " 0px 0px 0px 0px",
          minWidth: "70px",
          textAlign: "center",
        },
        filterCellStyle: {
          padding: "0px 0px 0px 0px",
        },
        render: (rowData) => {
          return (
            <button
              disabled={!rowData?.salesforceData}
              style={{
                fontSize: "12px",
                backgroundColor: "#FFCD4D",
                borderRadius: "4px",
                border: "1px solid #FFCD4D",
              }}
              onClick={(e) => {
                this.updatePopUpData({
                  show: true,
                  title: "",
                  children: (
                    <>
                      <br></br>
                      <span style={{ fontWeight: "bold" }}>
                        This action will sync the prospect back to SF.&nbsp;
                        {rowData?.lossRunUploaded && !rowData?.lossSummaryData
                          ? "The loss runs summary is not available yet. Do you want to proceed and return later to sync the loss runs summary?"
                          : "Do you want to proceed?"}
                      </span>
                    </>
                  ),
                  acceptBtn: "Yes",
                  acceptBtnCallback: async () => {
                    try {
                      this.sendToSalesforce(rowData);
                    } catch (error) {
                      console.log("error: ", error);
                    }
                  },
                });
              }}
            >
              Sync
            </button>
          );
        },
      });
    }

    if (tabName === "tab1") {
      columns.push({
        title: "Deal Won/Lost",
        field: "deal_won",
        sorting: false,
        disableSortBy: true,
        filtering: false,
        cellStyle: {
          padding: " 0px 0px 0px 0px",
          minWidth: "150px",
          textAlign: "center",
        },
        headerStyle: {
          // textAlign: "right",
          padding: " 0px 0px 0px 0px",
          backgroundColor: "rgb(179 75 62)",
          minWidth: "150px",
          textAlign: "center",
        },
        filterCellStyle: {
          padding: "0px 0px 0px 0px",
        },

        render: (rowData) => {
          let key = rowData.user_email_id;
          let isWon = false,
            isLost = false;
          if (key in dealMap) {
            isWon = dealMap[key] ? true : false;
            isLost = !dealMap[key] ? true : false;
          } else {
            // isWon = rowData.deal_won ? true : false;
            // isLost = rowData.deal_lost ? true : false;
          }
          let isConfirmed = false;
          if (confirmDealMap?.[key]) isConfirmed = true;
          else if (rowData.deal_confirmed) isConfirmed = true;
          return (
            <DealWonLostButtons
              rowData={rowData}
              updateDealMap={updateDealMap}
              isWon={isWon}
              isLost={isLost}
              isConfirmed={isConfirmed}
            />
          );
        },
      });
      if (loggedInGroups?.includes(process.env.REACT_APP_ADMIN_GROUP)) {
        columns.push({
          title: "Confirm Deal",
          field: "deal_confirmed",
          sorting: false,
          disableSortBy: true,
          filtering: false,
          cellStyle: {
            padding: " 0px 0px 0px 0px",
            minWidth: "55px",
            textAlign: "center",
          },
          headerStyle: {
            // textAlign: "right",
            padding: " 0px 0px 0px 5px",
            backgroundColor: "rgb(179 75 62)",
            textAlign: "left",
          },
          filterCellStyle: {
            padding: "0px 0px 0px 0px",
          },

          render: (rowData) => {
            let key = rowData.user_email_id;
            let isWon = false,
              isLost = false;
            if (key in dealMap) {
              isWon = dealMap[key] ? true : false;
              isLost = !dealMap[key] ? true : false;
            }
            // else {
            //   isWon = rowData.deal_won ? true : false;
            //   isLost = rowData.deal_lost ? true : false;
            // }
            let isConfirmed = false;
            if (confirmDealMap?.[key]) isConfirmed = true;
            else if (rowData.deal_confirmed) isConfirmed = true;
            return (
              <ConfirmDealButton
                rowData={rowData}
                // updateDealMap={updateDealMap}
                isWon={isWon}
                isLost={isLost}
                updatePopUpData={this.updatePopUpData}
                isConfirmed={isConfirmed}
                updateConfirmDealMap={updateConfirmDealMap}
                handleDealConfirm={handleDealConfirm}
              />
            );
          },
        });
      }
    }

    return (
      <div style={{ position: "relative" }} className="ies_dashboard">
        {/* {scrollLeft && (
          <button className="ChevronLeft" onClick={this.scrollLeft}>
            &lt;
          </button>
        )}
        {scrollRight && (
          <button className="ChevronRight" onClick={this.scrollRight}>
            &gt;
          </button>
        )} */}
        {tabName === "tab1" &&
          loggedInGroups?.includes(process.env.REACT_APP_ADMIN_GROUP) && (
            // <LoadingOverlay active={this.state.confirmAllLoading}>
            <button
              className="btn btn-primary "
              style={{ margin: "10px 0px" }}
              onClick={() => {
                this.handleConfirmAll(tabName);
              }}
            >
              Confirm All
            </button>
            // </LoadingOverlay>
          )}
        <ThemeProvider theme={defaultMaterialTheme}>
          <MaterialTable
            isLoading={isLoading}
            icons={tableIcons}
            data={rowDataList}
            columns={columns}
            onSelectionChange={(rows) => this.handleSelection(rows)}
            options={{
              selection:
                tabName === "tab1" &&
                loggedInGroups?.includes(process.env.REACT_APP_ADMIN_GROUP)
                  ? true
                  : false,
              selectionProps: (rowData) => {
                let key = rowData.user_email_id;
                return {
                  disabled:
                    rowData.deal_confirmed === true ||
                    (dealMap[key] !== true && dealMap[key] !== false) ||
                    (rowData?.pricingStatus !== "Approved" &&
                      rowData?.pricingStatus !== "Rejected" &&
                      rowData?.pricingStatus !== "Submitted"),
                  color: "primary",
                  backgroundColor: "#FFF",
                };
              },
              ...(this.props.loggedInGroups?.includes(
                process.env.REACT_APP_ADMIN_GROUP
              )
                ? {
                    exportFileName: "dashboard file",
                    exportButton: {
                      csv: true,
                      pdf: false,
                    },
                  }
                : {}),

              filtering: true,
              filter: true,
              paginationPosition: "both",
              padding: "dense",
              showTitle: false,
              thirdSortClick: false,
              pageSize: 10,
              pageSizeOptions: [10, 25, 50, 75, 100],
              // headerStyle: {
              //   fontSize: "12px",
              //   backgroundColor: "#003764",
              //   color: "#FFF",
              //   padding: "1px 5px 1px 5px",
              //   fontFamily: "Montserrat",
              // },
              // rowStyle: {
              //   fontSize: "12px",
              //   fontFamily: "Montserrat",
              // },
              // searchFieldStyle: {
              //   fontSize: "12px",
              //   fontFamily: "Montserrat",
              // },
              headerStyle: {
                fontSize: ismobile_font ? this.fontfunciton() : "1rem",
                backgroundColor: "#B34B3E",
                color: "#FFF",
                padding: "6px 15px 6px 2px",
                fontFamily: "Montserrat",
                lineHeight: "14px",
              },
              rowStyle: {
                fontSize: ismobile_font ? this.fontfunciton() : "0.95rem",
                color: "#000",
                fontFamily: "Montserrat",
              },
              searchFieldStyle: {
                fontSize: "0.95rem",
              },
            }}
            components={{
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  labelRowsPerPage={
                    <div style={{ fontSize: 12, display: "none" }}>
                      {props.labelRowsPerPage}
                    </div>
                  }
                  labelDisplayedRows={(row) => (
                    <div style={{ fontSize: 12, display: "none" }}>
                      {props.labelDisplayedRows(row)}
                    </div>
                  )}
                  SelectProps={{
                    style: {
                      fontSize: 12,
                    },
                  }}
                />
              ),
            }}
          />
        </ThemeProvider>
      </div>
    );
  };

  updatePopUpData = (popupData) => {
    this.setState({ popupData });
  };

  toastSuccess = (text) =>
    toast.success(text, {
      duration: 3000,
    });

  toastError = (text) =>
    toast.error(text, {
      duration: 3000,
    });

  getSalesforcePayrollInfo = (childrenloc, effDate) => {
    let payrollInfo = [];
    let totalPayroll = 0;
    try {
      for (let locationIndex in childrenloc) {
        let state = childrenloc[locationIndex].state.value;
        for (let classCodeIndex in childrenloc[locationIndex].classCodesInfo) {
          let { classCodeDescription, payroll, modifiedDate } =
            childrenloc[locationIndex].classCodesInfo[classCodeIndex];
          let splitArray = classCodeDescription.value.split(":");

          let classCode = splitArray[0];
          let description = splitArray.slice(1).join(":").trim();

          payroll = numeral(payroll.value).value();
          totalPayroll += payroll;

          payrollInfo.push({
            classCode,
            description,
            state,
            payroll: String(payroll),
            modifiedDate: modifiedDate?.value ?? effDate,
          });
        }
      }
    } catch (error) {
      console.log(error);
      payrollInfo = [];
      totalPayroll = 0;
    }

    return { payrollInfo, totalPayroll: String(totalPayroll) };
  };

  sendToSalesforce = async (rowData) => {
    try {
      $("#loader").css("display", "block");

      let {
        companyName,
        descriptionOfOperations,
        entityType,
        fein,
        phoneNumber,
        yearsInBusiness,
        effectiveDate,
        street1,
        street2,
        cityName1,
        cityName2,
        state1,
        state2,
        zipCode1,
        zipCode2,
      } = rowData?.companyProfile || {};

      let { accountId, opportunityId } = rowData?.salesforceData || {};

      try {
        var _companyName = rowData?.companyProfile?.companyName?.value,
          quoteGeneratedDate = rowData?.quoteData?.["date"],
          quoteEffectiveDate = rowData?.companyProfile?.effectiveDate.value;
      } catch (error) {
        console.log("++error", error);
      }

      let { payrollInfo, totalPayroll } = this.getSalesforcePayrollInfo(
        rowData?.childrenLoc,
        moment.utc(effectiveDate.value).local().format("M/D/YYYY")
      );

      let additional_data = await fetchAdditionalDetails(
        rowData.user_email_id,
        rowData.quoteData,
        accountId,
        _companyName,
        quoteGeneratedDate,
        quoteEffectiveDate
      );
      // console.log(additional_data);

      let data = {
        companyName: companyName?.value?.trim() || "",
        description: descriptionOfOperations?.value?.trim() || "",
        entityType: entityType?.value?.trim() || "",
        fein: fein?.value?.trim() || "",
        phoneNumber: phoneNumber?.value?.trim() || "",
        yearsInBusiness: yearsInBusiness?.value?.trim() || "",
        opportunityId,
        accountId,
        totalPayroll,
        effectiveDate: moment
          .utc(effectiveDate.value)
          .local()
          .format("M/D/YYYY"),
        billingStreet: street2?.value?.trim(),
        billingCity: cityName2?.value?.trim(),
        billingState: state2?.value?.trim()?.toUpperCase(),
        billingPostalCode: zipCode2?.value?.trim(),
        billingCountry: "USA",
        shippingStreet: street1?.value?.trim(),
        shippingCity: cityName1?.value?.trim(),
        shippingState: state1?.value?.trim()?.toUpperCase(),
        shippingPostalCode: zipCode1?.value?.trim(),
        shippingCountry: "USA",
        payrollInfo,
      };
      console.log({ ...data, ...additional_data });

      let response = await axios.put(awsUrl2 + "/api/sendToSalesforce", {
        ...data,
        ...additional_data,
      });
      console.log("++response", response);
      this.toastSuccess("Synced successfully");
    } catch (error) {
      console.log(error);
      this.toastError("Error while syncing");
    } finally {
      $("#loader").css("display", "none");
    }
  };

  render() {
    let {
      openModal,
      modalBody,
      agentButton,
      handleCloseModal,
      selected,
      tabName,
      isLoading,
      dataLoading,
    } = this.props;
    let { showModel, modalTitle, modalContent } = this.state.modalData;
    return (
      <div style={{ display: selected !== tabName ? "none" : "block" }}>
        {dataLoading ? (
          <div style={{ marginLeft: "50%" }}>
            <RotatingLines
              visible={true}
              height="40"
              width="40"
              color="black"
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
            <b>Data Loading.....</b>
          </div>
        ) : (
          <div style={{ marginLeft: "50%" }}></div>
        )}

        <div ref={this.myRef}>{this.setTableData()}</div>
        {agentButton ? (
          <Dialog open={openModal} onClose={handleCloseModal}>
            <DialogTitle>
              <p style={{ textAlign: "center" }}>
                {(modalBody.length > 0 &&
                  modalBody?.[0] !== "" &&
                  modalBody?.[0]?.comment !== "") ||
                modalBody?.[0]?.previousComment
                  ? "Comment History"
                  : "No Comment History"}
              </p>
              <IconButton
                aria-label="close"
                onClick={handleCloseModal}
                style={{ position: "absolute", right: "5px", top: "8px" }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {modalBody.length > 0 &&
                modalBody[0] !== "" &&
                modalBody.map((item, index) => {
                  let date = moment(item.commentTimestamp)
                    .format("M/D/YY h:mm A")
                    .split(" ")[0];
                  let time = moment(item.commentTimestamp)
                    .format("M/D/YY h:mm A")
                    .split(" ")[1];
                  if (item.comment !== "") {
                    if (item.commentTimestamp || item.email || item) {
                      return (
                        <div>
                          <div>
                            <span>
                              <b>{date}</b>
                            </span>
                            <span
                              style={{
                                marginLeft: "10px",
                                marginRight: "10px",
                              }}
                            >
                              <b>{time}</b>
                            </span>
                            <span>{item.email ? "- " : ""}</span>
                            <span>
                              <b>{item.email?.split("@")[0]}</b>
                            </span>
                          </div>
                          <div>
                            <p>
                              {item?.comment
                                ? item.comment
                                : modalBody[index + 1]?.comment
                                ? modalBody[index + 1].comment
                                : item}
                            </p>
                          </div>
                          <br />
                        </div>
                      );
                    }
                  } else {
                    return;
                  }
                })}
            </DialogContent>
          </Dialog>
        ) : (
          <Dialog open={openModal} onClose={handleCloseModal}>
            <DialogTitle>
              <p style={{ textAlign: "center" }}>
                {(modalBody.length > 0 &&
                  modalBody?.[0] !== "" &&
                  modalBody?.[0]?.comment !== "") ||
                modalBody?.[0]?.previousComment
                  ? "Comment History"
                  : "No Comment History"}
              </p>
              <IconButton
                aria-label="close"
                onClick={handleCloseModal}
                style={{ position: "absolute", right: "5px", top: "8px" }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {modalBody.length > 0 &&
                modalBody[0] !== "" &&
                modalBody.map((item, index) => {
                  let date = moment(item.commentTimestamp)
                    .format("M/D/YY h:mm A")
                    .split(" ")[0];
                  let time = moment(item.commentTimestamp)
                    .format("M/D/YY h:mm A")
                    .split(" ")[1];
                  if (item.comment !== "") {
                    if (item.commentTimestamp || item.email || item) {
                      return (
                        <div>
                          <div>
                            <span>
                              <b>{date}</b>
                            </span>
                            <span
                              style={{
                                marginLeft: "10px",
                                marginRight: "10px",
                              }}
                            >
                              <b>{time}</b>
                            </span>
                            <span>{item.email ? "- " : ""}</span>
                            <span>
                              <b>{item.email?.split("@")[0]}</b>
                            </span>
                          </div>
                          <div>
                            <p>
                              {item?.comment
                                ? item.comment
                                : modalBody[index + 1]?.comment
                                ? modalBody[index + 1].comment
                                : item}
                            </p>
                          </div>
                          <br />
                        </div>
                      );
                    }
                  } else {
                    return;
                  }
                })}
            </DialogContent>
          </Dialog>
        )}
        <Modal
          show={showModel}
          onHide={() => {
            this.setModalData({
              showModel: false,
              modalTitle: null,
              modalContent: null,
            });
          }}
          dialogClassName="model-download modal-dialog-centered"
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center w-100">
              {modalTitle}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalContent}</Modal.Body>
        </Modal>
        {/* <Toaster /> */}
        {this.state.popupData.show && (
          <Popup
            popupData={this.state.popupData}
            updatePopUpData={this.updatePopUpData}
          />
        )}
      </div>
    );
  }
}

export default DashboardLibertate;
